import { declensionRules } from "./declension-rules";
import { sandhiReverser } from "./sandhi";
import { CaseName, Num } from "./types";

export type Declension<D extends { type: string }> = {
    [E in D as E["type"]]: E;
}

export type NounOrAdjectiveDeclensionWord = Word &
{
    gen:string,
    num:string,
    case:string,
    stem: string
};

export type VerbDeclensionWord = Word &
{
    word: string,
    description: string,
    root: string,
}

export type IndeclinableParticibleWord = Word &
{
    description: string,
    stem: string;
}

export type IndeclinableVerbWord = Word &
{
    description: string,
    root: string
}

export type GenericTypeWord = Word &
{
    unknownType: string,
    description: string
}

export type IndeclinableWord = Word;

export type Word = {
    word: string;
    type: "nounOrAdjective"|"verb"|"indeclinableVerb"|"indeclianbleParticiple"|"generic"|"indeclinable"
};

export type Case = 
{
    name: CaseName;
    s: string|string[];
    d: string|string[];
    p: string|string[];
}

export type Rule =
{   pattern: string;
    irregular?: boolean;
    replace: {
        mas?: Array<Case>;
        fem?: Array<Case>;
        neu?: Array<Case>;
    }
}

export type DeclensionWord = 
{
    gen:string;
    num:string;
    word:string;
    case:string
};

const testWord = (word:string, testEnding:string|string[], pattern:RegExp, ending:string) =>
{
    testEnding = typeof testEnding == "string" ? [testEnding] : testEnding;
    //console.log(word, testEnding, ending, pattern);

    for (let i = 0; i < testEnding.length; i++)
    {
        let r = new RegExp(testEnding[i] + "$");
        if ( r.test(word) )
        {
            let testWord = word.replace(r, "");
            
            testWord += ending;

            if (pattern.test(testWord))
            {
                return testWord;
            }
        }
    }

    return null;
}

export const reverseDeclension = (word:string) =>
{
    const numbers = ["s", "d", "p"];
    let possibleMatches:DeclensionWord[] = [];

    let unsandhiedWords = sandhiReverser(word);

    
    for (let i = 0; i < declensionRules.length; i++)
    {
        let rule = declensionRules[i];
        let endingMatch = rule.pattern.match(/\(\?\<end\>(.*)\)/);
        
        if (endingMatch && endingMatch[1] != null)
        {
            let match = endingMatch[1];
            for (const [genKey, genValue] of Object.entries(rule.replace))
            {
                for (let j = 0; j < genValue.length; j++)
                {
                    let currCase = genValue[j];
                    numbers.forEach( c => {
                        unsandhiedWords.forEach( unsandhiedWord =>
                        {
                            let matchWord = testWord(unsandhiedWord, currCase[c], new RegExp(rule.pattern), match);
                        
                            if (matchWord)
                            {
                                possibleMatches.push({gen: genKey, num: c, word: matchWord, case: currCase.name })
                            }
                        })
                    });
                }
            }
        }
    }

    return possibleMatches;
}

export const getDeclensionRule = (word:string) =>
{
    return declensionRules.find((rule) => new RegExp(rule.pattern).test(word));
}