import styled from "styled-components";
import { LocalizedLink as Link } from "gatsby-theme-i18n";
import {media} from "../../styles/theme";

export const NavMenuButton = styled.div`
  ${media.desktop`
    @keyframes smooth-appear {
      to {
        transform: translateY(0);
        opacity:1;
      }
    }
    animation: smooth-appear .2s ease forwards;
    transform: translateY(-3px);
    opacity: 0;
  `}

  position: absolute;
  right: 0;
  top: 0;
  padding: 10px;
  font-size: 1.5rem;
  cursor: pointer;
  ${media.desktop`
    display: none;
  `}

`;

export const NavButton = styled(Link)`
  ${media.desktop`
    @keyframes smooth-appear {
      to {
        transform: translateY(0);
        opacity:1;
      }
    }
    animation: smooth-appear .2s ease forwards;
    transform: translateY(-3px);
    opacity: 0;
  `}

  &:first-letter {
    color: ${props => props.selected ? props.theme.colors.red : props.theme.colors.black}
  }
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: .15rem;
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: none;
  line-height: 50px;
  
  ${media.mobile`
    position: ${props => props.selected ? "absolute" : "auto"};
    top: 3px;
  `}
  ${media.mobile`
    display: ${props => props.selected || props.$opened ? "block" : "none"};
    padding-left: 0px;
  `}
  ${media.desktop`
    display: inline-block;
    border-bottom: ${props => props.selected ? "3px solid " + props.theme.colors.red + "ff" : "3px solid " + props.theme.colors.red + "00"};
    transition: border-bottom 0.2s linear;
  `}
  display: inline-block;
  color: ${props => props.selected ? props.theme.colors.red : props.theme.colors.dark}
`;

export const NavLinkButton = styled.a`

  ${media.desktop`
  @keyframes smooth-appear {
    to {
      transform: translateY(0);
      opacity:1;
    }
  }
    animation: smooth-appear .2s ease forwards;
    transform: translateY(-3px);
    opacity: 0;
  `}

  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: .15rem;
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: none;
  line-height: 3.5rem;
  cursor: pointer;
  ${media.mobile`
    position: ${props => props.selected ? "absolute" : "auto"};
    top: 0;
    display: ${props => props.selected || props.$opened ? "block" : "none"};
    padding-left: 0;
  `}
  ${media.desktop`
    display: inline-block;
  `}
  display: inline-block;
  color: ${props => props.selected ? props.theme.colors.red : props.theme.colors.black}
`;