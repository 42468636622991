import React from 'react';
import { User } from '../utils/auth';


export type UserData = {loading: boolean, user:User|undefined|null};

interface IUserContext {
  user: UserData;
  login: (user:string, password:string) => void;
  logout: () => void;
}
  
export const UserContext = React.createContext<IUserContext>({user: {loading:true, user: undefined}, login: (user, password) => {}, logout: () => {}});