import { getSunrise, getSunset } from 'sunrise-sunset-js';

export const isMobile = ():boolean =>
{
    return typeof navigator !== "undefined" && /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export const isBrowser = ():boolean => typeof window !== "undefined";

export const isSafari = ():boolean => typeof navigator !== "undefined" && navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') <= -1;

export const levDist = (r:string,a:string):number => {var t:number[][]=[],f=r.length,n=a.length;if(0==f)return n;if(0==n)return f;for(var v=f;v>=0;v--)t[v]=[];for(var v=f;v>=0;v--)t[v][0]=v;for(var e=n;e>=0;e--)t[0][e]=e;for(var v=1;f>=v;v++)for(var h=r.charAt(v-1),e=1;n>=e;e++){if(v==e&&t[v][e]>4)return f;var i=a.charAt(e-1),o=h==i?0:1,c=t[v-1][e]+1,u=t[v][e-1]+1,A=t[v-1][e-1]+o;c>u&&(c=u),c>A&&(c=A),t[v][e]=c,v>1&&e>1&&h==a.charAt(e-2)&&r.charAt(v-2)==i&&(t[v][e]=Math.min(t[v][e],t[v-2][e-2]+o))}return t[f][n]};

export const levenshteinDistance = (str1 = '', str2 = '') => {
   const track = Array(str2.length + 1).fill(null).map(() =>
   Array(str1.length + 1).fill(null));
   for (let i = 0; i <= str1.length; i += 1) {
      track[0][i] = i;
   }
   for (let j = 0; j <= str2.length; j += 1) {
      track[j][0] = j;
   }
   for (let j = 1; j <= str2.length; j += 1) {
      for (let i = 1; i <= str1.length; i += 1) {
         const indicator = str1[i - 1] === str2[j - 1] ? 0 : 1;
         track[j][i] = Math.min(
            track[j][i - 1] + 1, // deletion
            track[j - 1][i] + 1, // insertion
            track[j - 1][i - 1] + indicator, // substitution
         );
      }
   }
   return track[str2.length][str1.length];
};
 
export const getCurrentPosition = ():Promise<GeolocationPosition> =>
{
   return new Promise((resolve, reject) =>
   {
      navigator.geolocation.getCurrentPosition(resolve, reject);
   });
}

export const getSunriseAndSunset = ():Promise<{sunrise:number, sunset:number}> =>
{
   return getCurrentPosition()
   .then((position) =>
   {
      let sunset = getSunset(position.coords.latitude, position.coords.longitude).getTime();
      let sunrise = getSunrise(position.coords.latitude, position.coords.longitude).getTime();

      return {sunrise: sunrise, sunset: sunset};
   });
}

export const randFromSeed = (seed:number, max:number):number => {
   return `${(2**31-1&Math.imul(48271,seed))/2**31}`
    .split('')
    .slice(-10)
    .join('') % max;
 }
 

export const getChange = (props:any, prevProps:any, state?:any, prevState?:any) =>
{
   let p:string[] = [];
    let s:string[] = [];
    Object.entries(props).forEach(([key, val]) =>
      prevProps[key] !== val && p.push(key)
    );
    if (state) {
      Object.entries(state).forEach(([key, val]) =>
        prevState[key] !== val && s.push(key)
      );
    }
    if (p.length || s.length) {
      console.log("changedPROPS:", p.join(", "), "changedSTATE:", s.join(", "));
    }
}
//componentDidUpdate(prevProps:any, prevState:any) { getChange(this.props, prevProps, this.state, prevState); }