import styled from "styled-components";
import React, {useState, useEffect, useRef} from "react";
import Autosuggest from 'react-autosuggest'
import { itransToIast } from "../../utils/transliterator";
import { useTranslation } from "react-i18next";
import { withTranslation, WithTranslation } from "react-i18next";
import { RefObject } from "react";
import { isMobile } from "../../utils/utils";

const renderSuggestion = (suggestion:string) =>
{
  return <div>{suggestion}</div>;
}

var timeout:NodeJS.Timeout;

type AutoSuggestProps = WithTranslation & {
  value:string;
  disabled:boolean;
  autoIast:boolean;
  autoFocus?:boolean;
  id?:string;
  onChange:(value:string) => void;
  onGetSuggestion:(value:string) => Promise<string[]>;
  onSearch:(value:string, exactMatch:boolean) => void;
}

type AutoSuggestState = {
  suggestions:string[]
}

class AutoSuggestTrans extends React.Component<AutoSuggestProps, AutoSuggestState>
{
  state = { suggestions:[] };
  inputRef:RefObject<Autosuggest>;

  constructor(props:AutoSuggestProps) {
    super(props);
    this.inputRef = React.createRef();
  }

  componentDidMount() 
  {
    if (this.props.autoFocus)
    {
      this.setFocus();
    }
      
  }

  setFocus()
  {
    let e = document.getElementById(this.props.id || "input");
    if (e)
      e.focus();
    else
      setTimeout(this.setFocus, 200);
  }

  async onSuggestRequested(e) { 
    let value = itransToIast(e.value);
    
    const onGetSuggestion = this.props.onGetSuggestion;
    timeout = setTimeout( async () => {
      let result = await onGetSuggestion(value)
      this.setState({suggestions:result});
    }, 30);

  }

  render()
  {
    const inputProps = {
      value: this.props.value,
      type: "search",
      disabled: this.props.disabled,
      autoCapitalize: "off",
      id: this.props.id || "input",
      placeholder: this.props.t("search-a-phrase") + "...",
      onChange: (event, { newValue, method } : {newValue:string, method:"down"|"up"|"escape"|"enter"|"click"|"type"}) => { 
        this.props.onChange(this.props.autoIast && (method == "type" || method == "enter" || method == "click") ? itransToIast(newValue) : newValue);
      },
      onKeyDown: () => {
        if (timeout)
        {
          clearTimeout(timeout);
        }
      },
      onKeyUp: (event:KeyboardEvent) => { 
        if (event.key == "Enter") 
        {
          this.props.onSearch((this.props.autoIast ? itransToIast(event.target.value + " ") : event.target.value).trim()); 
          if (isMobile() && event.target?.blur) event.target.blur(); 
        }   
      }
    };
    
    return (
      <SearchInput>
        <Autosuggest
          suggestions={this.state.suggestions}
          onSuggestionsFetchRequested={this.onSuggestRequested.bind(this)}
          onSuggestionsClearRequested={() => {return []}}
          getSuggestionValue={(suggestion) => suggestion}
          onSuggestionSelected={(e, data) => {this. props.onSearch(data.suggestion, true);}}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
        />
      </SearchInput>
    );
  }
}

export const AutoSuggest = withTranslation()(AutoSuggestTrans);


const SearchInput = styled.div`
  & .react-autosuggest__container{
    width: 100%;
    position: relative; 
  }
  & .react-autosuggest__input {
    width: 100%;
    font-size: 0.85em;
    background-color: ${(props) => props.theme.colors.whiteTrans};
    
  }
  & .react-autosuggest__suggestions-container {
    display: none;
  }

  & .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 3em;
    width: 100%;
    padding: 0.3em;
    border: 1px solid ${(props) => props.theme.colors.gray};
    border-radius: 4px;
    background-color: ${(props) => props.theme.colors.white};
    font-size: 0.7em;
    z-index: 2;
  }

  & .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  & .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 3px 10px;
    border-radius: 4px;
  }

  & .react-autosuggest__suggestion--highlighted {
    background-color: ${(props) => props.theme.colors.pink};
  }

  & .react-autosuggest__suggestion-match {
    color: ${(props) => props.theme.colors.red};
    font-weight: bold;
  }

  @media print { display: none; }
`;