import { Rule } from "./declension";

export const declensionRules:Rule[] = [
    // IRREGULARS
    
    {   pattern: "^(g)(?<end>o)$", //GO specific
        irregular: true,
        replace: {
            fem: [
                { name: "nom", s: "auḥ",   d: "āvau",    p: "āvaḥ"   },
                { name: "voc", s: "auḥ",   d: "āvau",    p: "āvaḥ"  },
                { name: "acc", s: "ām",    d: "āvau",    p: "āḥ"  },
                { name: "ins", s: "avā",   d: "obhyām",  p: "obhiḥ"  },
                { name: "dat", s: "ave",   d: "obhyām",  p: "obhyaḥ"  },
                { name: "abl", s: "oḥ",    d: "obhyām",  p: "obhyaḥ"  },
                { name: "gen", s: "oḥ",    d: "avoḥ",    p: "avām"  },
                { name: "loc", s: "avi",   d: "avoḥ",    p: "oṣu"  }],
            mas: [
                { name: "nom", s: "auḥ",   d: "āvau",    p: "āvaḥ"   },
                { name: "voc", s: "auḥ",   d: "āvau",    p: "āvaḥ"  },
                { name: "acc", s: "ām",    d: "āvau",    p: "āḥ"  },
                { name: "ins", s: "avā",   d: "obhyām",  p: "obhiḥ"  },
                { name: "dat", s: "ave",   d: "obhyām",  p: "obhyaḥ"  },
                { name: "abl", s: "oḥ",    d: "obhyām",  p: "obhyaḥ"  },
                { name: "gen", s: "oḥ",    d: "avoḥ",    p: "avām"  },
                { name: "loc", s: "avi",   d: "avoḥ",    p: "oṣu"  }]}
    },
    /*{
        // FINISH
        pattern: "^(budh)(?<end>o)$", //GO specific
        replace: {
            fem: [
                { name: "nom", s: "auḥ",   d: "āvau",    p: "āvaḥ"   },
                { name: "voc", s: "auḥ",   d: "āvau",    p: "āvaḥ"  },
                { name: "acc", s: "ām",    d: "āvau",    p: "āḥ"  },
                { name: "ins", s: "avā",   d: "obhyām",  p: "obhiḥ"  },
                { name: "dat", s: "ave",   d: "obhyām",  p: "obhyaḥ"  },
                { name: "abl", s: "oḥ",    d: "obhyām",  p: "obhyaḥ"  },
                { name: "gen", s: "oḥ",    d: "avoḥ",    p: "avām"  },
                { name: "loc", s: "avi",   d: "avoḥ",    p: "oṣu"  }],
            mas: [
                { name: "nom", s: "auḥ",   d: "āvau",    p: "āvaḥ"   },
                { name: "voc", s: "auḥ",   d: "āvau",    p: "āvaḥ"  },
                { name: "acc", s: "ām",    d: "āvau",    p: "āḥ"  },
                { name: "ins", s: "avā",   d: "obhyām",  p: "obhiḥ"  },
                { name: "dat", s: "ave",   d: "obhyām",  p: "obhyaḥ"  },
                { name: "abl", s: "oḥ",    d: "obhyām",  p: "obhyaḥ"  },
                { name: "gen", s: "oḥ",    d: "avoḥ",    p: "avām"  },
                { name: "loc", s: "avi",   d: "avoḥ",    p: "oṣu"  }]}
    },*/
    {   pattern: "(lakṣm)(?<end>ī)$",
        irregular: true,
        replace: {
            fem: [
                { name: "nom", s: "īḥ",    d: "yau",     p: "yaḥ"   },
                { name: "voc", s: "i",     d: "yau",     p: "yaḥ"  },
                { name: "acc", s: "īm",    d: "yau",     p: "īḥ"  },
                { name: "ins", s: "yā",    d: "ībhyām",  p: "ībhiḥ"  },
                { name: "dat", s: "yai",   d: "ībhyām",  p: "ībhyaḥ"  },
                { name: "abl", s: "yāḥ",   d: "ībhyām",  p: "ībhyaḥ"  },
                { name: "gen", s: "yāḥ",   d: "yoḥ",     p: "īnām"  },
                { name: "loc", s: "yām",   d: "yoḥ",     p: "īṣu"  }]}
    },
    {   pattern: "(.*str)(?<end>ī)$",
        irregular: true,
        replace: {
            fem: [
                { name: "nom", s: "ī",     d: "iyau",    p: "iyaḥ"   },
                { name: "voc", s: "i",     d: "iyau",    p: "iyaḥ"  },
                { name: "acc", s: ["īm","iyam"],d: "iyau",p: ["īḥ","iyaḥ"]  },
                { name: "ins", s: "iyā",   d: "ībhyām",  p: "ībhiḥ"  },
                { name: "dat", s: "iyai",  d: "ībhyām",  p: "ībhyaḥ"  },
                { name: "abl", s: "iyāḥ",  d: "ībhyām",  p: "ībhyaḥ"  },
                { name: "gen", s: "iyāḥ",  d: "iyoḥ",    p: "īnām"  },
                { name: "loc", s: "iyām",  d: "iyoḥ",    p: "īṣu"  }]}
    },
    {   pattern: "^(sakh)(?<end>i)$",
        irregular: true,
        replace: {
            mas: [
                { name: "nom", s: "ā",     d: "āyau",    p: "āyaḥ"   },
                { name: "voc", s: "e",     d: "āyau",    p: "āyaḥ"  },
                { name: "acc", s: "āyam",  d: "āyau",    p: "īn"  },
                { name: "ins", s: "yā",    d: "ibhyām",  p: "ibhiḥ"  },
                { name: "dat", s: "ye",    d: "ibhyām",  p: "ibhyaḥ"  },
                { name: "abl", s: "yuḥ",   d: "ibhyām",  p: "ibhyaḥ"  },
                { name: "gen", s: "yuḥ",   d: "yoḥ",     p: "īnām"  },
                { name: "loc", s: "yau",   d: "yoḥ",     p: "iṣu"  }]
        }
    },
    {   pattern: "^(.*sakh)(?<end>i)$",
        irregular: true,
        replace: {
            mas: [
                { name: "nom", s: "ā",     d: "āyau",    p: "āyaḥ"   },
                { name: "voc", s: "e",     d: "āyau",    p: "āyaḥ"  },
                { name: "acc", s: "āyam",  d: "āyau",    p: "īn"  },
                { name: "ins", s: "inā",   d: "ibhyām",  p: "ibhiḥ"  },
                { name: "dat", s: "aye",   d: "ibhyām",  p: "ibhyaḥ"  },
                { name: "abl", s: "eḥ",    d: "ibhyām",  p: "ibhyaḥ"  },
                { name: "gen", s: "eḥ",    d: "yoḥ",     p: "īnām"  },
                { name: "loc", s: "au",    d: "yoḥ",     p: "iṣu"  }]
        }
    },
    {   pattern: "^(pat)(?<end>i)$",
        irregular: true,
        replace: {
            mas: [
                { name: "nom", s: "iḥ",    d: "ī",       p: "ayaḥ"   },
                { name: "voc", s: "e",     d: "ī",       p: "ayaḥ"  },
                { name: "acc", s: "im",    d: "ī",       p: "īn"  },
                { name: "ins", s: "yā",    d: "ibhyām",  p: "ibhiḥ"  },
                { name: "dat", s: "ye",    d: "ibhyām",  p: "ibhyaḥ"  },
                { name: "abl", s: "yuḥ",   d: "ibhyām",  p: "ibhyaḥ"  },
                { name: "gen", s: "yuḥ",   d: "yoḥ",     p: "īnām"  },
                { name: "loc", s: "yau",   d: "yoḥ",     p: "iṣu"  }]
        }
    },
    {   pattern: "(.*(?:asth|dadh|sakth|akṣ))(?<end>i)$",
        irregular: true,
        replace: {
            neu: [
                { name: "nom", s: "i",     d: "inī",     p: "īni"   },
                { name: "voc", s: ["e","i"],d: "inī",    p: "īni"  },
                { name: "acc", s: "i",     d: "inī",     p: "īni"  },
                { name: "ins", s: "nā",    d: "ibhyām",  p: "ibhiḥ"  },
                { name: "dat", s: "ne",    d: "ibhyām",  p: "ibhyaḥ"  },
                { name: "abl", s: "naḥ",   d: "ibhyām",  p: "ibhyaḥ"  },
                { name: "gen", s: "naḥ",   d: "noḥ",     p: "nām"  },
                { name: "loc", s: ["ni","ani"],d: "noḥ", p: "iṣu"  }]}
    },
    {   pattern: "(.*kroṣṭ)(?<end>u)$",
        irregular: true,
        replace: {
            mas: [
                { name: "nom", s: "ā",     d: "ārau",    p: "āraḥ"   },
                { name: "voc", s: "o",     d: "ārau",    p: "āraḥ"  },
                { name: "acc", s: "āram",  d: "ārau",    p: "ūn"  },
                { name: "ins", s: ["unā","rā"],d: "ubhyām",     p: "ubhiḥ"  },
                { name: "dat", s: ["ave","re"],d: "ubhyām",     p: "ubhyaḥ"  },
                { name: "abl", s: ["oḥ","uḥ"], d: "ubhyām",     p: "ubhyaḥ"  },
                { name: "gen", s: ["oḥ","uḥ"], d: ["voḥ","roḥ"],p: "ūnām"  },
                { name: "loc", s: ["au","ari"],d: ["voḥ","roḥ"],p: "uṣu"  }]
        }
    },
    {   pattern: "(^n)(?<end>ṛ)$",
        replace: {
            mas: [
                { name: "nom", s: "ā",     d: "arau",    p: "araḥ"   },
                { name: "voc", s: "aḥ",    d: "arau",    p: "araḥ"  },
                { name: "acc", s: "aram",  d: "arau",    p: "ṝn"  },
                { name: "ins", s: "rā",    d: "ṛbhyām",  p: "ṛbhiḥ"  },
                { name: "dat", s: "re",    d: "ṛbhyām",  p: "ṛbhyaḥ"  },
                { name: "abl", s: "uḥ",    d: "ṛbhyām",  p: "ṛbhyaḥ"  },
                { name: "gen", s: "uḥ",    d: "roḥ",     p: ["ṛṇām","ṝṇām"]  },
                { name: "loc", s: "ari",   d: "roḥ",     p: "ṛṣu"  }]
        }
    },
    {   pattern: "(.*n)(?<end>ṛ)$",
        replace: {
            mas: [
                { name: "nom", s: "ā",     d: "ārau",    p: "āraḥ"   },
                { name: "voc", s: "aḥ",    d: "ārau",    p: "āraḥ"  },
                { name: "acc", s: "āram",  d: "ārau",    p: "ṝn"  },
                { name: "ins", s: "rā",    d: "ṛbhyām",  p: "ṛbhiḥ"  },
                { name: "dat", s: "re",    d: "ṛbhyām",  p: "ṛbhyaḥ"  },
                { name: "abl", s: "uḥ",    d: "ṛbhyām",  p: "ṛbhyaḥ"  },
                { name: "gen", s: "uḥ",    d: "roḥ",     p: ["ṛṇām","ṝṇām"]  },
                { name: "loc", s: "ari",   d: "roḥ",     p: "ṛṣu"  }]
        }
    },


    // ------------------------------REGULAR VOWEL ---------------------------

    {   pattern: "(.*)(?<end>ā)$",
        replace: {
            mas: [
                { name: "nom", s: "āḥ",    d: "au",      p: "āḥ"   },
                { name: "voc", s: "āḥ",    d: "au",      p: "āḥ"  },
                { name: "acc", s: "ām",    d: "au",      p: "aḥ"  },
                { name: "ins", s: "ā",     d: "ābhyām",  p: "ābhiḥ"  },
                { name: "dat", s: "e",     d: "ābhyām",  p: "ābhyaḥ"  },
                { name: "abl", s: "aḥ",    d: "ābhyām",  p: "ābhyaḥ"  },
                { name: "gen", s: "aḥ",    d: "oḥ",      p: "ām"  },
                { name: "loc", s: "i",     d: "oḥ",      p: "āsu"  }],
            fem: [
                { name: "nom", s: "ā",     d: "e",       p: "āḥ"   },
                { name: "voc", s: "e",     d: "e",       p: "āḥ"  },
                { name: "acc", s: "ām",    d: "e",       p: "āḥ"  },
                { name: "ins", s: "ayā",   d: "ābhyām",  p: "ābhiḥ"  },
                { name: "dat", s: "āyai",  d: "ābhyām",  p: "ābhyaḥ"  },
                { name: "abl", s: "āyāḥ",  d: "ābhyām",  p: "ābhyaḥ"  },
                { name: "gen", s: "āyāḥ",  d: "ayoḥ",    p: "ānām"  },
                { name: "loc", s: "āyām",  d: "ayoḥ",    p: "āsu"  }]}
    },
    {   pattern: "(.*)(?<end>ai)$",
        replace: {
            mas: [
                { name: "nom", s: "āḥ",    d: "āyau",    p: "āyaḥ"   },
                { name: "voc", s: "āḥ",    d: "āyau",    p: "āyaḥ"  },
                { name: "acc", s: "āyam",  d: "āyau",    p: "āyaḥ"  },
                { name: "ins", s: "āyā",   d: "ābhyām",  p: "ābhiḥ"  },
                { name: "dat", s: "āye",   d: "ābhyām",  p: "ābhyaḥ"  },
                { name: "abl", s: "āyaḥ",  d: "ābhyām",  p: "ābhyaḥ"  },
                { name: "gen", s: "āyaḥ",  d: "āyoḥ",    p: "āyām"  },
                { name: "loc", s: "āyi",   d: "āyoḥ",    p: "āsu"  }]}
    },
    {   pattern: "(.*)(?<end>au)$",
        replace: {
            fem: [
                { name: "nom", s: "auḥ",   d: "āvau",    p: "āvaḥ"   },
                { name: "voc", s: "auḥ",   d: "āvau",    p: "āvaḥ"  },
                { name: "acc", s: "āvam",  d: "āvau",    p: "āvaḥ"  },
                { name: "ins", s: "āvā",   d: "aubhyām", p: "aubhiḥ"  },
                { name: "dat", s: "āve",   d: "aubhyām", p: "aubhyaḥ"  },
                { name: "abl", s: "āvaḥ",  d: "āvoḥ",    p: "āvām"  },
                { name: "gen", s: "āvaḥ",  d: "āvoḥ",    p: "āvām"  },
                { name: "loc", s: "āvi",   d: "āvoḥ",    p: "auṣu"  }]}
    },
    {   pattern: "(.*)(?<end>a)$",
        replace: {
            mas: [
                { name: "nom", s: "aḥ",    d: "au",      p: "āḥ"   },
                { name: "voc", s: "a",     d: "au",      p: "āḥ"  },
                { name: "acc", s: "am",    d: "au",      p: "ān"  },
                { name: "ins", s: "ena",   d: "ābhyām",  p: "aiḥ"  },
                { name: "dat", s: "āya",   d: "ābhyām",  p: "ebhyaḥ"  },
                { name: "abl", s: "āt",    d: "ābhyām",  p: "ebhyaḥ"  },
                { name: "gen", s: "asya",  d: "ayoḥ",    p: "ānām"  },
                { name: "loc", s: "e",     d: "ayoḥ",    p: "eṣu"  }],
            fem: [
                { name: "nom", s: "ā",     d: "e",       p: "āḥ"   },
                { name: "voc", s: "e",     d: "e",       p: "āḥ"  },
                { name: "acc", s: "ām",    d: "e",       p: "āḥ"  },
                { name: "ins", s: "ayā",   d: "ābhyām",  p: "ābhiḥ"  },
                { name: "dat", s: "āyai",  d: "ābhyām",  p: "ābhyaḥ"  },
                { name: "abl", s: "āyāḥ",  d: "ābhyām",  p: "ābhyaḥ"  },
                { name: "gen", s: "āyāḥ",  d: "ayoḥ",    p: "ānām"  },
                { name: "loc", s: "āyām",  d: "ayoḥ",    p: "āsu"  }],
            neu: [
                { name: "nom", s: "am",    d: "e",       p: "āni"   },
                { name: "voc", s: "a",     d: "e",       p: "āni"  },
                { name: "acc", s: "am",    d: "e",       p: "āni"  },
                { name: "ins", s: "ena",   d: "ābhyām",  p: "aiḥ"  },
                { name: "dat", s: "āya",   d: "ābhyām",  p: "ebhyaḥ"  },
                { name: "abl", s: "āt",    d: "ābhyām",  p: "ebhyaḥ"  },
                { name: "gen", s: "asya",  d: "ayoḥ",    p: "ānām"  },
                { name: "loc", s: "e",     d: "ayoḥ",    p: "eṣu"  }]}
    },
    {   pattern: "(.*)(?<end>i)$",
        replace: {
            mas: [
                { name: "nom", s: "iḥ",    d: "ī",       p: "ayaḥ"   },
                { name: "voc", s: "e",     d: "ī",       p: "ayaḥ"  },
                { name: "acc", s: "im",    d: "ī",       p: "īn"  },
                { name: "ins", s: "inā",   d: "ibhyām",  p: "ibhiḥ"  },
                { name: "dat", s: "aye",   d: "ibhyām",  p: "ibhyaḥ"  },
                { name: "abl", s: "eḥ",    d: "ibhyām",  p: "ibhyaḥ"  },
                { name: "gen", s: "eḥ",    d: "yoḥ",     p: "īnām"  },
                { name: "loc", s: "au",    d: "yoḥ",     p: "iṣu"  }],
            fem: [
                { name: "nom", s: "iḥ",    d: "ī",       p: "ayaḥ"   },
                { name: "voc", s: "e",     d: "ī",       p: "ayaḥ"  },
                { name: "acc", s: "im",    d: "ī",       p: "īḥ"  },
                { name: "ins", s: "yā",    d: "ibhyām",  p: "ibhiḥ"  },
                { name: "dat", s: ["aye","yai"],d: "ibhyām", p: "ibhyaḥ"  },
                { name: "abl", s: ["eḥ","yāḥ"], d: "ibhyām", p: "ibhyaḥ"  },
                { name: "gen", s: ["eḥ","yāḥ"], d: "yoḥ",    p: "īnām"  },
                { name: "loc", s: ["au","yām"], d: "yoḥ",    p: "iṣu"  }],
            neu: [
                { name: "nom", s: "i",     d: "inī",     p: "īni"   },
                { name: "voc", s: ["e","i"],d: "inī",    p: "īni"  },
                { name: "acc", s: "i",     d: "inī",     p: "īni"  },
                { name: "ins", s: "inā",   d: "ibhyām",  p: "ibhiḥ"  },
                { name: "dat", s: "ine",   d: "ibhyām",  p: "ibhyaḥ"  },
                { name: "abl", s: "inaḥ",  d: "ibhyām",  p: "ibhyaḥ"  },
                { name: "gen", s: "inaḥ",  d: "inoḥ",    p: "īnām"  },
                { name: "loc", s: "ini",   d: "inoḥ",    p: "iṣu"  }]}
    },
    {   pattern: "(.*)(?<end>u)$",
        replace: {
            mas: [
                { name: "nom", s: "uḥ",    d: "ū",       p: "avaḥ"   },
                { name: "voc", s: "o",     d: "ū",       p: "avaḥ"  },
                { name: "acc", s: "um",    d: "ū",       p: "ūn"  },
                { name: "ins", s: "unā",   d: "ubhyām",  p: "ubhiḥ"  },
                { name: "dat", s: "ave",   d: "ubhyām",  p: "ubhyaḥ"  },
                { name: "abl", s: "oḥ",    d: "ubhyām",  p: "ubhyaḥ"  },
                { name: "gen", s: "oḥ",    d: "voḥ",     p: "ūnām"  },
                { name: "loc", s: "au",    d: "voḥ",     p: "uṣu"  }],
            fem: [
                { name: "nom", s: "uḥ",    d: "ū",       p: "avaḥ"   },
                { name: "voc", s: "o",     d: "ū",       p: "avaḥ"  },
                { name: "acc", s: "um",    d: "ū",       p: "ūḥ"  },
                { name: "ins", s: "vā",    d: "ubhyām",  p: "ubhiḥ"  },
                { name: "dat", s: ["ave","vai"],d: "ubhyām", p: "ubhyaḥ"  },
                { name: "abl", s: ["oḥ","vāḥ"], d: "ubhyām", p: "ubhyaḥ"  },
                { name: "gen", s: ["oḥ","vāḥ"], d: "voḥ",    p: "ūnām"  },
                { name: "loc", s: ["au","vām"], d: "voḥ",    p: "uṣu"  }],
            neu: [
                { name: "nom", s: "u",     d: "unī",     p: "ūni"   },
                { name: "voc", s: ["o","u"],d: "unī",    p: "ūni"  },
                { name: "acc", s: "u",     d: "unī",     p: "ūni"  },
                { name: "ins", s: "unā",   d: "ubhyām",  p: "ubhiḥ"  },
                { name: "dat", s: "une",   d: "ubhyām",  p: "ubhyaḥ"  },
                { name: "abl", s: "unaḥ",  d: "ubhyām",  p: "ubhyaḥ"  },
                { name: "gen", s: "unaḥ",  d: "unoḥ",    p: "ūnām"  },
                { name: "loc", s: "uni",   d: "unoḥ",    p: "uṣu"  }]}
    },
    {   pattern: "^((?!.*(?:a|ā|i|ī[^$]|ṛ|ṝ|ḷ|ḹ|u|ū|e|ai|o|au)).*)(?<end>ī)$", //MONOSYLLABIC
        irregular: true,
        replace: {
            fem: [
                { name: "nom", s: "īḥ",    d: "iyau",    p: "iyaḥ"   },
                { name: "voc", s: "īḥ",    d: "iyau",    p: "iyaḥ"  },
                { name: "acc", s: "iyam",  d: "iyau",    p: "iyaḥ"  },
                { name: "ins", s: "iyā",   d: "ībhyām",  p: "ībhiḥ"  },
                { name: "dat", s: ["iye","iyai"], d: "ībhyām", p: "ībhyaḥ"  },
                { name: "abl", s: ["iyaḥ","iyāḥ"],d: "ībhyām", p: "ībhyaḥ"  },
                { name: "gen", s: ["iyaḥ","iyāḥ"],d: "iyoḥ",   p: ["iyām","īnām"]  },
                { name: "loc", s: ["iyi","iyām"], d: "iyoḥ",   p: "īṣu"  }]}
    },
    {   pattern: "^((?!.*(?:a|ā|i|ī|ṛ|ṝ|ḷ|ḹ|u|ū[^$]|e|ai|o|au)).*)(?<end>ū)$", //MONOSYLLABIC, FIX: should work also for compounds!!!
        irregular: true,
        replace: {
            fem: [
                { name: "nom", s: "ūḥ",    d: "uvau",    p: "uvaḥ"   },
                { name: "voc", s: "ūḥ",    d: "uvau",    p: "uvaḥ"  },
                { name: "acc", s: "uvam",  d: "uvau",    p: "uvaḥ"  },
                { name: "ins", s: "uvā",   d: "ūbhyām",  p: "ūbhiḥ"  },
                { name: "dat", s: ["uve","uvai"], d: "ūbhyām", p: "ūbhyaḥ"  },
                { name: "abl", s: ["uvaḥ","uvāḥ"],d: "ūbhyām", p: "ūbhyaḥ"  },
                { name: "gen", s: ["uvaḥ","uvāḥ"],d: "uvoḥ",   p: ["uvām","ūnām"]  },
                { name: "loc", s: ["uvi","uvām"], d: "uvoḥ",   p: "ūṣu"  }]}
    },
    {   pattern: "(.*)(?<end>ī)$",
        replace: {
            fem: [
                { name: "nom", s: "ī",     d: "yau",     p: "yaḥ"   },
                { name: "voc", s: "i",     d: "yau",     p: "yaḥ"  },
                { name: "acc", s: "īm",    d: "yau",     p: "īḥ"  },
                { name: "ins", s: "yā",    d: "ībhyām",  p: "ībhiḥ"  },
                { name: "dat", s: "yai",   d: "ībhyām",  p: "ībhyaḥ"  },
                { name: "abl", s: "yāḥ",   d: "ībhyām",  p: "ībhyaḥ"  },
                { name: "gen", s: "yāḥ",   d: "yoḥ",     p: "īnām"  },
                { name: "loc", s: "yām",   d: "yoḥ",     p: "īṣu"  }],
            mas: [
                { name: "nom", s: "ī",     d: "yau",     p: "yaḥ"   },
                { name: "voc", s: "i",     d: "yau",     p: "yaḥ"  },
                { name: "acc", s: "īm",    d: "yau",     p: "īḥ"  },
                { name: "ins", s: "yā",    d: "ībhyām",  p: "ībhiḥ"  },
                { name: "dat", s: "yai",   d: "ībhyām",  p: "ībhyaḥ"  },
                { name: "abl", s: "yāḥ",   d: "ībhyām",  p: "ībhyaḥ"  },
                { name: "gen", s: "yāḥ",   d: "yoḥ",     p: "īnām"  },
                { name: "loc", s: "yām",   d: "yoḥ",     p: "īṣu"  }]}
    },
    {   pattern: "(.*)(?<end>ū)$",
        replace: {
            fem: [
                { name: "nom", s: "ūḥ",    d: "vau",     p: "vaḥ"   },
                { name: "voc", s: "u",     d: "vau",     p: "vaḥ"  },
                { name: "acc", s: "ūm",    d: "vau",     p: "ūḥ"  },
                { name: "ins", s: "vā",    d: "ūbhyām",  p: "ūbhiḥ"  },
                { name: "dat", s: "vai",    d: "ūbhyām",  p: "ūbhyaḥ"  },
                { name: "abl", s: "vāḥ",   d: "ūbhyām",  p: "ūbhyaḥ"  },
                { name: "gen", s: "vāḥ",   d: "voḥ",     p: "ūnām"  },
                { name: "loc", s: "vām",   d: "voḥ",     p: "uṣu"  }],
            mas: [
                { name: "nom", s: "ūḥ",    d: "vau",     p: "vaḥ"   },
                { name: "voc", s: "u",     d: "vau",     p: "vaḥ"  },
                { name: "acc", s: "ūm",    d: "vau",     p: "ūḥ"  },
                { name: "ins", s: "vā",    d: "ūbhyām",  p: "ūbhiḥ"  },
                { name: "dat", s: "vai",    d: "ūbhyām",  p: "ūbhyaḥ"  },
                { name: "abl", s: "vāḥ",   d: "ūbhyām",  p: "ūbhyaḥ"  },
                { name: "gen", s: "vāḥ",   d: "voḥ",     p: "ūnām"  },
                { name: "loc", s: "vām",   d: "voḥ",     p: "uṣu"  }]}
    },
    {   pattern: "(.*(?:bhrāt|māt|pit|jāmāt|devṛ|savyeṣṭ|duhit|nanānd|yāt))(?<end>ṛ)$", // FAMILY MEMBERS - WHAT IS THE FULL LIST ?????????????????????????????????????????????????
        irregular: true,
        replace: {
            mas: [
                { name: "nom", s: "ā",     d: "arau",    p: "araḥ"   },
                { name: "voc", s: "aḥ",    d: "arau",    p: "araḥ"  },
                { name: "acc", s: "aram",  d: "arau",    p: "ṝn"  },
                { name: "ins", s: "rā",    d: "ṛbhyām",  p: "ṛbhiḥ"  },
                { name: "dat", s: "re",    d: "ṛbhyām",  p: "ṛbhyaḥ"  },
                { name: "abl", s: "uḥ",    d: "ṛbhyām",  p: "ṛbhyaḥ"  },
                { name: "gen", s: "uḥ",    d: "roḥ",     p: "ṝṇām"  },
                { name: "loc", s: "ari",   d: "roḥ",     p: "ṛṣu"  }],
            fem: [
                { name: "nom", s: "ā",     d: "arau",    p: "araḥ"   },
                { name: "voc", s: "aḥ",    d: "arau",    p: "araḥ"  },
                { name: "acc", s: "aram",  d: "arau",    p: "ṝḥ"  },
                { name: "ins", s: "rā",    d: "ṛbhyām",  p: "ṛbhiḥ"  },
                { name: "dat", s: "re",    d: "ṛbhyām",  p: "ṛbhyaḥ"  },
                { name: "abl", s: "uḥ",    d: "ṛbhyām",  p: "ṛbhyaḥ"  },
                { name: "gen", s: "uḥ",    d: "roḥ",     p: "ṝṇām"  },
                { name: "loc", s: "ari",   d: "roḥ",     p: "ṛṣu"  }]}
    },
    {   pattern: "((?:.*[tṭ])|svas)(?<end>ṛ)$",
        irregular: true,
        replace: {
            mas: [
                { name: "nom", s: "ā",     d: "ārau",    p: "āraḥ"   },
                { name: "voc", s: "aḥ",    d: "ārau",    p: "āraḥ"  },
                { name: "acc", s: "āram",  d: "ārau",    p: "ṝn"  },
                { name: "ins", s: "rā",    d: "ṛbhyām",  p: "ṛbhiḥ"  },
                { name: "dat", s: "re",    d: "ṛbhyām",  p: "ṛbhyaḥ"  },
                { name: "abl", s: "uḥ",    d: "ṛbhyām",  p: "ṛbhyaḥ"  },
                { name: "gen", s: "uḥ",    d: "roḥ",     p: "ṝṇām"  },
                { name: "loc", s: "ari",   d: "roḥ",     p: "ṛṣu"  }],
            fem: [
                { name: "nom", s: "ā",     d: "ārau",    p: "āraḥ"   },
                { name: "voc", s: "aḥ",    d: "ārau",    p: "āraḥ"  },
                { name: "acc", s: "āram",  d: "ārau",    p: "ṝḥ"  },
                { name: "ins", s: "rā",    d: "ṛbhyām",  p: "ṛbhiḥ"  },
                { name: "dat", s: "re",    d: "ṛbhyām",  p: "ṛbhyaḥ"  },
                { name: "abl", s: "uḥ",    d: "ṛbhyām",  p: "ṛbhyaḥ"  },
                { name: "gen", s: "uḥ",    d: "roḥ",     p: "ṝṇām"  },
                { name: "loc", s: "ari",   d: "roḥ",     p: "ṛṣu"  }],
            neu: [
                { name: "nom", s: "ṛ",     d: "ṛṇī",     p: "ṝṇi"   },
                { name: "voc", s: "aḥ",    d: "ārau",    p: "āraḥ"  },
                { name: "acc", s: "āram",  d: "ārau",    p: "ṝn"  },
                { name: "ins", s: ["ṛṇā","rā"], d: "ṛbhyām",      p: "ṛbhiḥ"  },
                { name: "dat", s: ["ṛṇe","re"], d: "ṛbhyām",      p: "ṛbhyaḥ"  },
                { name: "abl", s: ["ṛṇaḥ","uḥ"],d: "ṛbhyām",      p: "ṛbhyaḥ"  },
                { name: "gen", s: ["ṛṇaḥ","uḥ"],d: ["ṛṇoḥ","roḥ"],p: "ṝṇām"  },
                { name: "loc", s: ["ṛṇi","ari"],d: ["ṛṇoḥ","roḥ"],p: "ṛṣu"  }]}
    },

    // ------------------------------REGULAR CONSONANTS ---------------------------
    {   pattern: "(.*(?<char>ṇ|k|kh|g|gh|t|th|p|bh|d|dh|b|ph))(?<end>)$", //kh, g, gh, b, ph are added according to Muller // D and DH why?????
        replace: {
            mas: [
                { name: "nom", s: "",      d: "au",    p: "aḥ"   },
                { name: "voc", s: "",      d: "au",    p: "aḥ"  },
                { name: "acc", s: "am",    d: "au",    p: "aḥ"  },
                { name: "ins", s: "ā",     d: "bhyām", p: "bhiḥ" },
                { name: "dat", s: "e",     d: "bhyām", p: "bhyaḥ" },
                { name: "abl", s: "aḥ",    d: "bhyām", p: "bhyaḥ" },
                { name: "gen", s: "aḥ",    d: "oḥ",    p: "ām"  },
                { name: "loc", s: "i",     d: "oḥ",    p: "su"  }],
            fem: [
                { name: "nom", s: "",      d: "au",    p: "aḥ"   },
                { name: "voc", s: "",      d: "au",    p: "aḥ"  },
                { name: "acc", s: "am",    d: "au",    p: "aḥ"  },
                { name: "ins", s: "ā",     d: "bhyām", p: "bhiḥ" },
                { name: "dat", s: "e",     d: "bhyām", p: "bhyaḥ" },
                { name: "abl", s: "aḥ",    d: "bhyām", p: "bhyaḥ" },
                { name: "gen", s: "aḥ",    d: "oḥ",    p: "ām"  },
                { name: "loc", s: "i",     d: "oḥ",    p: "su"  }],
            neu: [
                { name: "nom", s: "",      d: "ī",     p: "i"   },
                { name: "voc", s: "",      d: "ī",     p: "i"  },
                { name: "acc", s: "",      d: "ī",     p: "i"  },
                { name: "ins", s: "ā",     d: "bhyām", p: "bhiḥ" },
                { name: "dat", s: "e",     d: "bhyām", p: "bhyaḥ" },
                { name: "abl", s: "aḥ",    d: "bhyām", p: "bhyaḥ" },
                { name: "gen", s: "aḥ",    d: "oḥ",    p: "ām"  },
                { name: "loc", s: "i",     d: "oḥ",    p: "su"  }]},
    },
    {   pattern: "(.*(?<char>c|j|r))(?<end>)$",
        replace: {
            mas: [
                { name: "nom", s: "",      d: "au",    p: "aḥ"   },
                { name: "voc", s: "",      d: "au",    p: "aḥ"  },
                { name: "acc", s: "am",    d: "au",    p: "aḥ"  },
                { name: "ins", s: "ā",     d: "bhyām", p: "bhiḥ" },
                { name: "dat", s: "e",     d: "bhyām", p: "bhyaḥ" },
                { name: "abl", s: "aḥ",    d: "bhyām", p: "bhyaḥ" },
                { name: "gen", s: "aḥ",    d: "oḥ",    p: "ām"  },
                { name: "loc", s: "i",     d: "oḥ",    p: "su"  }],
            fem: [
                { name: "nom", s: "",      d: "au",    p: "aḥ"   },
                { name: "voc", s: "",      d: "au",    p: "aḥ"  },
                { name: "acc", s: "am",    d: "au",    p: "aḥ"  },
                { name: "ins", s: "ā",     d: "bhyām", p: "bhiḥ" },
                { name: "dat", s: "e",     d: "bhyām", p: "bhyaḥ" },
                { name: "abl", s: "aḥ",    d: "bhyām", p: "bhyaḥ" },
                { name: "gen", s: "aḥ",    d: "oḥ",    p: "ām"  },
                { name: "loc", s: "i",     d: "oḥ",    p: "su"  }],
            neu: [
                { name: "nom", s: "",      d: "ī",     p: "i"   },
                { name: "voc", s: "",      d: "ī",     p: "i"  },
                { name: "acc", s: "",      d: "ī",     p: "i"  },
                { name: "ins", s: "ā",     d: "bhyām", p: "bhiḥ" },
                { name: "dat", s: "e",     d: "bhyām", p: "bhyaḥ" },
                { name: "abl", s: "aḥ",    d: "bhyām", p: "bhyaḥ" },
                { name: "gen", s: "aḥ",    d: "oḥ",    p: "ām"  },
                { name: "loc", s: "i",     d: "oḥ",    p: "su"  }]}
    },
    {   pattern: "(.*)(?<end>as)$", 
        replace: {
            mas: [
                { name: "nom", s: "āḥ",      d: "asau",    p: "asaḥ"   },
                { name: "voc", s: "aḥ",      d: "asau",    p: "asaḥ"  },
                { name: "acc", s: "asam",    d: "asau",    p: "asaḥ"  },
                { name: "ins", s: "asā",     d: "obhyām", p: "obhiḥ" },
                { name: "dat", s: "ase",     d: "obhyām", p: "obhyaḥ" },
                { name: "abl", s: "asaḥ",    d: "obhyām", p: "obhyaḥ" },
                { name: "gen", s: "asaḥ",    d: "asoḥ",    p: "asām"  },
                { name: "loc", s: "asi",     d: "asoḥ",    p: ["aḥsu","assu"]  }],
            fem: [
                { name: "nom", s: "āḥ",      d: "asau",    p: "asaḥ"   },
                { name: "voc", s: "aḥ",      d: "asau",    p: "asaḥ"  },
                { name: "acc", s: "asam",    d: "asau",    p: "asaḥ"  },
                { name: "ins", s: "asā",     d: "obhyām", p: "obhiḥ" },
                { name: "dat", s: "ase",     d: "obhyām", p: "obhyaḥ" },
                { name: "abl", s: "asaḥ",    d: "obhyām", p: "obhyaḥ" },
                { name: "gen", s: "asaḥ",    d: "asoḥ",    p: "asām"  },
                { name: "loc", s: "asi",     d: "asoḥ",    p: ["aḥsu","assu"]  }],
            neu: [
                { name: "nom", s: "aḥ",      d: "asī",     p: "āṁsi"   },
                { name: "voc", s: "aḥ",      d: "asī",     p: "āṁsi"  },
                { name: "acc", s: "aḥ",      d: "asī",     p: "āṁsi"  },
                { name: "ins", s: "asā",     d: "obhyām", p: "obhiḥ" },
                { name: "dat", s: "ase",     d: "obhyām", p: "obhyaḥ" },
                { name: "abl", s: "asaḥ",    d: "obhyām", p: "obhyaḥ" },
                { name: "gen", s: "asaḥ",    d: "asoḥ",    p: "asām"  },
                { name: "loc", s: "asi",     d: "asoḥ",    p: ["aḥsu","assu"]  }]}
    },
    {   pattern: "(.*)(?<end>is)$", 
        replace: {
            mas: [
                { name: "nom", s: "iḥ",      d: "iṣau",    p: "iṣaḥ"   },
                { name: "voc", s: "iḥ",      d: "iṣau",    p: "iṣaḥ"  },
                { name: "acc", s: "iṣam",    d: "iṣau",    p: "iṣaḥ"  },
                { name: "ins", s: "iṣā",     d: "irbhyām", p: "irbhiḥ" },
                { name: "dat", s: "iṣe",     d: "irbhyām", p: "irbhyaḥ" },
                { name: "abl", s: "iṣaḥ",    d: "irbhyām", p: "irbhyaḥ" },
                { name: "gen", s: "iṣaḥ",    d: "iṣoḥ",    p: "iṣām"  },
                { name: "loc", s: "iṣi",     d: "iṣoḥ",    p: ["iḥṣu","iṣṣu"]  }],
            fem: [
                { name: "nom", s: "iḥ",      d: "iṣau",    p: "iṣaḥ"   },
                { name: "voc", s: "iḥ",      d: "iṣau",    p: "iṣaḥ"  },
                { name: "acc", s: "iṣam",    d: "iṣau",    p: "iṣaḥ"  },
                { name: "ins", s: "iṣā",     d: "irbhyām", p: "irbhiḥ" },
                { name: "dat", s: "iṣe",     d: "irbhyām", p: "irbhyaḥ" },
                { name: "abl", s: "iṣaḥ",    d: "irbhyām", p: "irbhyaḥ" },
                { name: "gen", s: "iṣaḥ",    d: "iṣoḥ",    p: "iṣām"  },
                { name: "loc", s: "iṣi",     d: "iṣoḥ",    p: ["iḥṣu","iṣṣu"]  }],
            neu: [
                { name: "nom", s: "iḥ",      d: "iṣī",    p: "īṁṣi"   },
                { name: "voc", s: "iḥ",      d: "iṣī",    p: "īṁṣi"  },
                { name: "acc", s: "iḥ",      d: "iṣī",    p: "īṁṣi"  },
                { name: "ins", s: "iṣā",     d: "irbhyām", p: "irbhiḥ" },
                { name: "dat", s: "iṣe",     d: "irbhyām", p: "irbhyaḥ" },
                { name: "abl", s: "iṣaḥ",    d: "irbhyām", p: "irbhyaḥ" },
                { name: "gen", s: "iṣaḥ",    d: "iṣoḥ",    p: "iṣām"  },
                { name: "loc", s: "iṣi",     d: "iṣoḥ",    p: ["iḥṣu","iṣṣu"]  }]}
    },
    {   pattern: "(.*)(?<end>us)$",
        replace: {
            mas: [
                { name: "nom", s: "uḥ",      d: "uṣau",    p: "uṣaḥ"   },
                { name: "voc", s: "uḥ",      d: "uṣau",    p: "uṣaḥ"  },
                { name: "acc", s: "uṣam",    d: "uṣau",    p: "uṣaḥ"  },
                { name: "ins", s: "uṣā",     d: "urbhyām", p: "urbhiḥ" },
                { name: "dat", s: "uṣe",     d: "urbhyām", p: "urbhyaḥ" },
                { name: "abl", s: "uṣaḥ",    d: "urbhyām", p: "urbhyaḥ" },
                { name: "gen", s: "uṣaḥ",    d: "uṣoḥ",    p: "uṣām"  },
                { name: "loc", s: "uṣi",     d: "uṣoḥ",    p: ["uḥṣu","uṣṣu"]  }],
            fem: [
                { name: "nom", s: "uḥ",      d: "uṣau",    p: "uṣaḥ"   },
                { name: "voc", s: "uḥ",      d: "uṣau",    p: "uṣaḥ"  },
                { name: "acc", s: "uṣam",    d: "uṣau",    p: "uṣaḥ"  },
                { name: "ins", s: "uṣā",     d: "urbhyām", p: "urbhiḥ" },
                { name: "dat", s: "uṣe",     d: "urbhyām", p: "urbhyaḥ" },
                { name: "abl", s: "uṣaḥ",    d: "urbhyām", p: "urbhyaḥ" },
                { name: "gen", s: "uṣaḥ",    d: "uṣoḥ",    p: "uṣām"  },
                { name: "loc", s: "uṣi",     d: "uṣoḥ",    p: ["uḥṣu","uṣsu"]  }],
            neu: [
                { name: "nom", s: "uḥ",      d: "uṣī",    p: "ūṁṣi"   },
                { name: "voc", s: "uḥ",      d: "uṣī",    p: "ūṁṣi"  },
                { name: "acc", s: "uḥ",      d: "uṣī",    p: "ūṁṣi"  },
                { name: "ins", s: "uṣā",     d: "urbhyām", p: "urbhiḥ" },
                { name: "dat", s: "uṣe",     d: "urbhyām", p: "urbhyaḥ" },
                { name: "abl", s: "uṣaḥ",    d: "urbhyām", p: "urbhyaḥ" },
                { name: "gen", s: "uṣaḥ",    d: "uṣoḥ",    p: "uṣām"  },
                { name: "loc", s: "uṣi",     d: "uṣoḥ",    p: ["uḥṣu","uṣṣu"]  }]}
    },
    {   pattern: "(.*)(?<end>an)$", //kh, g, gh, b, ph are added according to Muller // D and DH why?????
        replace: {
            mas: [
                { name: "nom", s: "ā",          d: "ānau",    p: "ānaḥ"   },
                { name: "voc", s: "an",         d: "ānau",    p: "ānaḥ"  },
                { name: "acc", s: "ānam",       d: "ānau",    p: "ñaḥ"  },
                { name: "ins", s: "ñā",         d: "abhyām", p: "abhiḥ" },
                { name: "dat", s: "ñe",         d: "abhyām", p: "abhyaḥ" },
                { name: "abl", s: "ñaḥ",        d: "abhyām", p: "abhyaḥ" },
                { name: "gen", s: "ñaḥ",        d: "ñoḥ",    p: "ñām"  },
                { name: "loc", s: ["ñi","ani"], d: "ñoḥ",    p: "asu"  }],
            neu: [
                { name: "nom", s: "a",          d: "anī",     p: "āni"   },
                { name: "voc", s: ["a", "an"],  d: "anī",     p: "āni"  },
                { name: "acc", s: "a",          d: "anī",     p: "āni"  },
                { name: "ins", s: "anā",        d: "abhyām",  p: "abhiḥ" },
                { name: "dat", s: "ane",        d: "abhyām",  p: "abhyaḥ" },
                { name: "abl", s: "anaḥ",       d: "abhyām",  p: "abhyaḥ" },
                { name: "gen", s: "anaḥ",       d: "anoḥ",    p: "anām"  },
                { name: "loc", s: "ani",        d: "anoḥ",    p: "asu"  }]}
    },
    {   pattern: "(.*)(?<end>in)$", //kh, g, gh, b, ph are added according to Muller // D and DH why?????
        replace: {
            mas: [
                { name: "nom", s: "ī",       d: "inau",    p: "inaḥ"   },
                { name: "voc", s: "in",      d: "inau",    p: "inaḥ"  },
                { name: "acc", s: "inam",    d: "inau",    p: "inaḥ"  },
                { name: "ins", s: "inā",     d: "ibhyām", p: "ibhiḥ" },
                { name: "dat", s: "ine",     d: "ibhyām", p: "ibhyaḥ" },
                { name: "abl", s: "inaḥ",    d: "ibhyām", p: "ibhyaḥ" },
                { name: "gen", s: "inaḥ",    d: "inoḥ",    p: "inām"  },
                { name: "loc", s: "ini",     d: "inoḥ",    p: "iṣu"  }],
            fem: [
                { name: "nom", s: "inī",   d: "inyau",     p: "inyaḥ"   },
                { name: "voc", s: "ini",   d: "inyau",     p: "inyaḥ"  },
                { name: "acc", s: "inīm",  d: "inyau",     p: "inīḥ"  },
                { name: "ins", s: "inyā",  d: "inībhyām", p: "inībhiḥ" },
                { name: "dat", s: "inyai", d: "inībhyām", p: "inībhyaḥ" },
                { name: "abl", s: "inyāḥ", d: "inībhyām", p: "inībhyaḥ" },
                { name: "gen", s: "inyāḥ", d: "inyoḥ",    p: "inīnām"  },
                { name: "loc", s: "inyām", d: "inyoḥ",    p: "inīṣu"  }],
            neu: [
                { name: "nom", s: "i",          d: "inī",     p: "īni"   },
                { name: "voc", s: ["in", "i"],  d: "inī",     p: "īni"  },
                { name: "acc", s: "i",          d: "inī",     p: "īni"  },
                { name: "ins", s: "inā",        d: "ibhyām",  p: "ibhiḥ" },
                { name: "dat", s: "ine",        d: "ibhyām",  p: "ibhyaḥ" },
                { name: "abl", s: "inaḥ",       d: "ibhyām",  p: "ibhyaḥ" },
                { name: "gen", s: "inaḥ",       d: "inoḥ",    p: "inām"  },
                { name: "loc", s: "ini",        d: "inoḥ",    p: "iṣu"  }]}
    },
    {   pattern: "(.*[^kcṭtpgjḍdb])(?<end>h)$", //kh, g, gh, b, ph are added according to Muller // D and DH why?????
        replace: {
            mas: [
                { name: "nom", s: "ṭ",       d: "hau",    p: "haḥ"   },
                { name: "voc", s: "ṭ",       d: "hau",    p: "haḥ"  },
                { name: "acc", s: "ham",     d: "hau",    p: "haḥ"  },
                { name: "ins", s: "hā",      d: "ḍbhyām", p: "ḍbhiḥ" },
                { name: "dat", s: "he",      d: "ḍbhyām", p: "ḍbhyaḥ" },
                { name: "abl", s: "haḥ",     d: "ḍbhyām", p: "ḍbhyaḥ" },
                { name: "gen", s: "haḥ",     d: "hoḥ",    p: "hām"  },
                { name: "loc", s: "hi",      d: "hoḥ",    p: ["ṭsu", "ṭtsu"]  }],
            fem: [
                { name: "nom", s: "ṭ",       d: "hau",    p: "haḥ"   },
                { name: "voc", s: "ṭ",       d: "hau",    p: "haḥ"  },
                { name: "acc", s: "ham",     d: "hau",    p: "haḥ"  },
                { name: "ins", s: "hā",      d: "ḍbhyām", p: "ḍbhiḥ" },
                { name: "dat", s: "he",      d: "ḍbhyām", p: "ḍbhyaḥ" },
                { name: "abl", s: "haḥ",     d: "ḍbhyām", p: "ḍbhyaḥ" },
                { name: "gen", s: "haḥ",     d: "hoḥ",    p: "hām"  },
                { name: "loc", s: "hi",      d: "hoḥ",    p: ["ṭsu", "ṭtsu"]  }],
            neu: [
                { name: "nom", s: "ṭ",       d: "hī",     p: "ṁhi"   },
                { name: "voc", s: "ṭ",       d: "hī",     p: "ṁhi"  },
                { name: "acc", s: "ṭ",     d: "hī",     p: "ṁhi"  },
                { name: "ins", s: "hā",      d: "ḍbhyām", p: "ḍbhiḥ" },
                { name: "dat", s: "he",      d: "ḍbhyām", p: "ḍbhyaḥ" },
                { name: "abl", s: "haḥ",     d: "ḍbhyām", p: "ḍbhyaḥ" },
                { name: "gen", s: "haḥ",     d: "hoḥ",    p: "hām"  },
                { name: "loc", s: "hi",      d: "hoḥ",    p: ["ṭsu", "ṭtsu"]  }]}
    },
];