import React, {useEffect, useState, ReactNode} from "react";
import styled from "styled-components";
import { NavButton, NavLinkButton, NavMenuButton } from "./navbutton";
import { Row } from "../row";
import { useTranslation } from "react-i18next";
import { useLocation } from '@reach/router';
import { Post } from "../../types/posts";
import {media} from "../../styles/theme";
import { Login } from "../login/login";
import { FindOnPage } from "../findonpage/findonpage";
import {logout, User} from "../../utils/auth";
import { useLocalization } from "gatsby-theme-i18n";
import { isMobile } from "../../utils/utils";
import { UserContext } from "../../state/usercontext";


const Nav = React.memo((props:{pages:Post[], children:ReactNode, title?:string, onTouchStart?:(e:TouchEvent)=>void, onTouchMove?:(e:TouchEvent)=>void, onTouchEnd?:(e:TouchEvent)=>void, swipePos?:number, hidden:boolean}) => {
  const { t } = useTranslation();
  const loc = useLocation();

  let pathMatch = loc.pathname.match(/^\/[a-z]{2}\/(.*?)(?:\/|$)/);
  let path = pathMatch ? pathMatch[1] : "";
  let scriptureMatch = loc.pathname.match(/^\/[a-z]{2}\/scriptures\/(\w+)/);
  let scripture = scriptureMatch ? scriptureMatch[1] : null;
  path = scripture ? "scripture" : path;
  let pages = props.pages ? props.pages : [];
  const [state, setState] = useState(false);
  const [isShowLogin, showLogin] = useState(false);
  const [isShowFind, showFind] = useState(false);
  

  const user = React.useContext(UserContext);
  const userId = typeof window !== "undefined" ? localStorage.getItem("userId") : undefined;
  let predictedUser:"member"|"guest"|undefined = userId !== undefined && userId !== null ? (userId.length ? "member" : "guest") : undefined;

  useEffect(() => {
    let navRow = document.getElementById("navRow");
    if (navRow) {
      navRow.style.display = props.hidden || (!isMobile() && predictedUser === undefined) ? "none" : "block";
    }
  });
  
  const isPwa = () => {

      return typeof window !== "undefined" && ["fullscreen", "standalone", "minimal-ui"].some(
          (displayMode) => window.matchMedia('(display-mode: ' + displayMode + ')').matches
      );
  }


  const mergedUser = ((user.user.loading && predictedUser == "member") || (!user.user.loading && user.user.user)) ? true : false;
  
//<NavButton to="/essentials" selected={path == "essentials"} $opened={state}>{t("essentials")}</NavButton>
//<NavButton to="/gallery" selected={path == "gallery"} $opened={state}>{t("gallery")}</NavButton>
//<NavButton to="/search2" selected={path == "search2"} $opened={state}>{t("search")}</NavButton>
//<Separator>·</Separator>

  return (
    <StyledNav $opened={state} onTouchStart={props.onTouchStart} onTouchMove={props.onTouchMove} onTouchEnd={props.onTouchEnd} $swipePos={props.swipePos}>
      <NavRow id="navRow">  
          <NavButton to="/" selected={path == ""} $opened={state} onClick={() => setState(false)}>{t("home")}</NavButton>
          <Separator>·</Separator>
          <NavButton to="/scriptures" selected={path == "scriptures" || path == "scripture"} $opened={state} onClick={() => setState(false)}>{(path == "scripture" && props.title) ? props.title : t("scriptures")}</NavButton>
          <Separator>·</Separator>
          {pages.map((post:Post) => ( [<NavButton to={"/pages/" + post.fields.slug} selected={loc.pathname.indexOf("/pages/" + post.fields.slug) > -1 } key={post.fields.slug} $opened={state} onClick={() => setState(false)}>{post.frontmatter.title}</NavButton>,<Separator key={"separator_" + post.fields.slug}>·</Separator>] ) )}
          <NavButton to="/tools" selected={path == "tools"} $opened={state} onClick={() => setState(false)}>{t("tools")}</NavButton>
          {mergedUser && [<Separator key="separator">·</Separator>,<NavButton key="studies" to="/studies" selected={path == "studies"} $opened={state} onClick={() => setState(false)}>{t("studies")}</NavButton>]}
          <Separator>·</Separator>
          <NavButton to="/dictionary" selected={path == "dictionary"} $opened={state} onClick={() => setState(false)}>{t("dictionary")}</NavButton>
          <Separator>·</Separator>
          <NavButton to="/search" selected={path == "search"} $opened={state} onClick={() => setState(false)}>{t("search")}</NavButton>
          <Separator>·</Separator>
          {isPwa() && isMobile() && <NavLinkButton selected={false} onClick={() => {showLogin(false); showFind(true); setState(false);}} $opened={state} >{t("find-on-page")}</NavLinkButton>}
          {isPwa() && <NavLinkButton selected={false} onClick={() => navigator.share({title: document.title, url: window.location.href})} $opened={state} >{t("share")}</NavLinkButton>}
          <NavLinkButton selected={false} onClick={() => {showFind(false); if (mergedUser) logout(); else { showLogin(true); setState(false); }}} $opened={state} >{mergedUser ? t("logout") : t("login")}</NavLinkButton>
          <NavMenuButton className="icon-menu" onClick={() => { setState(!state) }} />
          {props.children}
        {isShowLogin && <Login onClose={() => showLogin(false)}></Login>}
        {isShowFind && <FindOnPage onClose={() => showFind(false)}></FindOnPage>}
      </NavRow>
      <div  dangerouslySetInnerHTML={{
                __html: `<script>
                    document.getElementById("navRow").style.display = "none";
                </script>`
      }}></div>
    </StyledNav>
  );
});


const StyledNav = styled.nav<{$swipePos:number, $opened:boolean}>`
  width: 100%;
  background-color: ${(props) => props.theme.colors.bright};
  border-top: ${(props) => 1+props.$swipePos}px solid ${(props) => props.theme.colors.lightgray};
  border-bottom: 1px solid ${(props) => props.theme.colors.lightgray};
  position: sticky;
  top: -1px;
  box-shadow: 0 -2px #eee;
  z-index: 100;
  text-align: center;
  min-height: 56px;
  box-sizing: content-box;
  ${media.mobile`
    padding-top: ${(props) => props.$opened ? "56px" : "0px"};
  `}
  box-shadow: 0px 16px 30px rgba(0, 0, 0, 0.03);
  transition: border-top 0.1s;
  @media print { display: none; }
`;

//display: ${(props) => props.$hidden ? "none" : "block"};
const NavRow = styled(Row)`
  ${media.desktop`
    text-align: center;
  `}

  @keyframes smooth-appear {
      to {
        opacity:1;
      }
    }
    animation: smooth-appear .2s ease forwards;
    opacity: 0;
`;

const Separator = styled.div`
  display: inline-block;
  color: ${props => props.theme.colors.gray};
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  ${media.mobile`
    display: none;
  `}
`;

export { Nav };
