import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import {media} from "../../styles/theme";

const ribbonWidth = 110;
const ribbonHeight = 35;
const ribbonPadding = 9;
const ribbonColor = "#ffc300"; 

const StyledAnniversary = styled.div`

  background-color: ${ribbonColor};
  color: ${(props) => props.theme.colors.white};
  display: inline-block;
  width: ${ribbonWidth}px;
  position: absolute;
  opacity: 0.85;
  ${media.desktop`
    left: 150px;
    font-size: 0.6em;
  `}
  ${media.mobile`
    left: 5%;
    font-size: 0.6em;
  `}
  text-decoration: none;
  padding: 0 ${ribbonPadding}px ${ribbonPadding / 2}px;
  text-align: center;

  &:after {
      content: '';
      display: block;
      width:0;
      height:0;
      border-style: solid;
      border-color: ${ribbonColor} transparent transparent transparent;
      border-width: ${ribbonHeight - (ribbonPadding / 2)}px ${ribbonWidth / 2}px 0 ${ribbonWidth / 2}px;

      position: absolute;
      top: 100%;
      left: 0;

  }
`;

const AnniversaryInner = styled.span`
  display: block;
  border-left: 1px solid ${(props) => props.theme.colors.white};
  border-right: 1px solid ${(props) => props.theme.colors.white};
  position: relative;
  height: 100%;
  padding: 35px 5px 12px;
  text-transform: uppercase;

  &:before,
  &:after {
      content: '';
      display: block;

      //css triangle
      width:0;
      height:0;
      border-style: solid;
      border-color: #ffc300 transparent transparent transparent;
      border-width: ${ribbonHeight -ribbonPadding}px ${(ribbonWidth / 2) - ribbonPadding}px 0 ${(ribbonWidth / 2) - ribbonPadding}px;
      margin-top: -1px;

      position: absolute;
      top: 100%;
      left: -1px;
      z-index: 5;
  }


  &:before {
      border-color: ${(props) => props.theme.colors.white} transparent transparent transparent;
      border-width: ${ribbonHeight - ribbonPadding}px ${(ribbonWidth / 2) - ribbonPadding}px 0 ${(ribbonWidth / 2) - ribbonPadding}px;
      margin-top: 0;
  }
`;

const No = styled.div`
  font-size: 1.75em;
`;


type AnniversaryProps = {
  anniversary: number;
}



export const Anniversary = (props:AnniversaryProps) => {
  const { t } = useTranslation();
  return (
    <StyledAnniversary>
      <AnniversaryInner><No>#{props.anniversary}</No>{" " + t("anniversary")}</AnniversaryInner>
    </StyledAnniversary>
  );
};

export default Anniversary;
