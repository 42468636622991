import { LocalizedLink as Link } from "gatsby-theme-i18n"
import React from "react";
import styled, { useTheme } from "styled-components";
import { Theme } from "../../styles/theme";

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 5px;
  padding-bottom: 10px;
  &:focus {
      border: none;
      outline: 0;
    }
`;

const StyledImg = styled.img`
  width: 280px;
  image-rendering: crisp-edges;
  &:focus {
      border: none;
      outline: 0;
    }
`;

const StyledImgText = styled.img`
  width: 268px;
  filter: ${(props) => props.theme.colors.invertFilter};
  &:focus {
      border: none;
      outline: 0;
    }
`;

const Logo = React.memo((props:{theme:Theme}) => {
  const theme = useTheme() as Theme;
  return (
    <StyledLink to="/">
      <StyledImg src={theme.logoImage} alt="Mahānaya" width="280" height="140"></StyledImg>
    </StyledLink>
  );
}, () => true);

export { Logo };
