import React from "react"
import Layout from "./src/components/layout/layout";

// Pass all props (hence the ...props) to the layout component so it has access to things like pageContext or location
const wrapPageElement = ({ element, props }) => {
  
  const newElement = React.cloneElement(
    element,  // I18nextProvider
    element.props,
    React.cloneElement(
      element.props.children,  // I18nextContext.Provider
      element.props.children.props,
      React.createElement(
        Layout,
        props,
        element.props.children.props.children,
      ),
    ),
  );

  return newElement;
};

export default wrapPageElement;
