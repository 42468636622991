import React from "react";
import styled from "styled-components";
import { Row } from "../row";

const StyledFooter = styled.footer`
  margin-top: 56px;
  border-width: 0;
  border-top: 1px solid ${props => props.theme.colors.lightgray};
  padding: 1.5em 0;
  color: #646464;
  text-align: center;
  box-shadow: 0px -16px 16px rgba(0,0,0,0.02);
  font-variant: small-caps;
  line-height: 1.5em;
  @media print {
    box-shadow: none;
  }
`;


const FooterContainer = styled(Row)`
  text-align: center;
  position: relative;
`;

const LogoIcon = styled.img`
  width: 3em;
  height: 3em;
  display: inline;
  border: 1px solid ${(props) => props.theme.colors.lightgray};
  border-radius: 50%;
  position: absolute;
  z-index: -1;
  left: 0;
  
  @media print { display: none; }
`;

const FooterTitle = styled.div`
  letter-spacing: 2px;
  color: ${(props) => props.theme.colors.darkgray};
`

const Footer: React.FC = () => {
  return (
    <StyledFooter>
      <FooterContainer>
        <LogoIcon src="/favicons/apple-touch-icon.png" alt="mahānaya.org" />
        {`© 2020 - ${new Date().getFullYear()}`}
        <FooterTitle>Mahānaya.org</FooterTitle>
      </FooterContainer>
    </StyledFooter>
  );
};

export { Footer };
