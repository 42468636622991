import styled from "styled-components";

const Button = styled.button<{$primary?:boolean}>`
  display: inline-block;
  height: 3.5em;
  padding: 0 1em;
  color: ${props => props.theme.colors.black};
  background-color: ${props => props.$primary ? props.theme.colors.pink : props.theme.colors.bright};
  ${(props) => props.theme.colors.bright}
  text-align: center;
  font-size: 0.8em;
  font-weight: normal;
  letter-spacing: .05em;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  border-radius: 0.3em;
  border: 1px solid ${props => props.theme.colors.gray};
  cursor: pointer;
  box-sizing: border-box;
`;

export { Button };
