var itrans_iast = {
    'aa' : 'ā',
    'ii' : 'ī',
    'uu' : 'ū',
    'RRi' : 'ṛ',
    'RRI' : 'ṝ',
    'LLi' : 'ḷ',
    'LLI' : 'ḹ',
    'M' : 'ṃ',
    'H' : 'ḥ',
    'N' : 'ṇ',
    '~n' : 'ñ',
    '~N' : 'ṅ',
    'T' : 'ṭ',
    'D' : 'ḍ',
    'sh' : 'ś',
    'Sh' : 'ṣ',
    'GY' : 'jñ'
};

export function itransToIast(text:string) {
    var ret = "";
    var len = text.length;
    for (var i = 0; i < len; i++) {
        if (i < len - 2 && (text.charAt(i)+text.charAt(i+1)+text.charAt(i+2) in itrans_iast) ) {
            ret += itrans_iast[text.charAt(i)+text.charAt(i+1)+text.charAt(i+2)];
            i += 2;
        } else if (i < len - 1 && (text.charAt(i)+text.charAt(i+1) in itrans_iast) ) {
            ret += itrans_iast[text.charAt(i)+text.charAt(i+1)];
            i++;
        } else if (i < len - 1 && (text.charAt(i) in itrans_iast) ) {
            ret += itrans_iast[text.charAt(i)];
        } else ret += text.charAt(i);
    }
    return ret;
}