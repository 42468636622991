import React, {useState} from "react";
import styled from "styled-components";
import { RadioButton } from "../button/radio-button";
import {media} from "../../styles/theme";


type CategoryFilterProps = {
  categoryList:Array<{name:string, title:string, disabled?:boolean}>;
  hideOnMobile:boolean;
  name:string;
  defaultState?:string;
  state?:string;
  onChange:(value:string)=>void;
  className?:string;
}

export const CategoryFilter = (props:CategoryFilterProps) => {
  const [state, setState] = useState(props.defaultState);
  
  return (
    <CategoryFilterContainer $hideOnMobile={props.hideOnMobile} className={props.className}>
      {props.categoryList.map(cat => {
      return (<CategoryRadioButton key={cat.name} name={props.name} value={cat.name} disabled={cat.disabled} selected={props.state !== undefined ? props.state == cat.name : state == cat.name} onSelect={(v:string) => {setState(v); props.onChange(v);}}>{cat.title}</CategoryRadioButton>);
      }
    )}
    </CategoryFilterContainer>
  );
};


const CategoryFilterContainer = styled.div<{$hideOnMobile:boolean,$sticky?:boolean}>`
  display: flex;
  position: "absolute";

  gap: 5px;
  align-items: stretch;
  margin-bottom: 10px;
  justify-content: flex-end;
  z-index: 100;
  ${media.mobile`
    display: ${props => props.$hideOnMobile ? "none" : "flex"};
    margin-top: ${props => props.$sticky ? "15px" : "0px"};
    margin-right: ${props => props.$sticky ? "35px" : "0"};
  `}

  @media print { display: none; }
`;

const CategoryRadioButton = styled(RadioButton)<{disabled?:boolean}>`
  & label {
    height: 22px;
    text-transform: none;
    padding: 2px 8px;
    line-height: 15px;
    letter-spacing: 0;
    font-weight: normal;
    min-width: 50px;
    opacity: ${props => props.disabled ? "0.5" : "1"};
  }
`;