import styled from "styled-components";
import React, {ReactNode, useState} from "react";
import { Button } from "./button";

type ToggleButtonProps = {
  onClick: (state:boolean) => void;
  defaultState: boolean;
  state?:boolean;
  children?:ReactNode;
  className?:string;
  title?:string
}

export const ToggleButton = (props:ToggleButtonProps) => {
  const [internalState, setInternalState] = useState(props.defaultState);
  return (
    <StyledToggleButton onClick={() => {setInternalState(!internalState); props.onClick(props.state !== undefined ? !props.state : !internalState);}} title={props.title} $state={props.state !== undefined ? props.state : internalState} className={props.className}>
      {props.children}
    </StyledToggleButton>
  );
};

const StyledToggleButton = styled(Button)<{$state:boolean}>`
  text-decoration: ${props => props.$state ? "none" : "line-through"}
`;