import React, { useState, createRef } from "react";
import styled from "styled-components";
import { withTranslation, WithTranslation } from "react-i18next";
import {AutoSuggest} from '../autosuggest';
import Sanscript from "@sanskrit-coders/sanscript";
import {isSafari, levenshteinDistance} from "../../utils/utils";

import { DeclensionWindow, parseGenders } from "../declension/declension-window";
import { DeclensionWord, reverseDeclension } from "../../grammar/declension";
import { Gender } from "../../grammar/types";
import * as dict from '../../utils/dictionary-database';
import {SearchResult} from '../../utils/dictionary-database';
import { MultiToggleButton, RadioButton } from "../button";
import { DictionaryList } from "./dictionary-list";
import { DatabaseSources, LanguagePairs } from "../../utils/dictionary-data";
import { useTranslation } from "react-i18next"
import { Button } from "../button/button";

var DictionaryDatabaseClass: typeof dict.DictionaryDatabase;  
if (typeof window !== "undefined")
{
  DictionaryDatabaseClass = require(`../../utils/dictionary-database`).DictionaryDatabase;
}

const langButtonStates = [
  { key: LanguagePairs.SanskritEnglish, value: LanguagePairs.SanskritEnglish },
  { key: LanguagePairs.EnglishSanskrit, value: LanguagePairs.EnglishSanskrit },
];

type DictionarySearchProps = WithTranslation & {
  onChange:(query:string) => void;
  onNavigate:(query:string, lang:string) => void;
  isSticky:boolean;
  query:string;
  uri:string;
  searchText?:string;
  inputId:string;
  watchNavigation?:boolean;
}

type DictionarySearchState = {
  dictLang:string;
  results:SearchResult;
  query:string;
  inputValue:string;
  abFilter:string;
  abListShow:boolean;
  loading:boolean;
  availableGenders:{[key: string]:Gender[]};
  declension:{word:string,gender:Gender}|null;
  stemWords:DeclensionWord[];
  stemList:{[key: string]:DeclensionWord[]};
  noResults:boolean;
}

class DictionarySearch extends React.Component<DictionarySearchProps, DictionarySearchState> // prop, state
{ 
  state:DictionarySearchState = { dictLang: LanguagePairs.SanskritEnglish, results:[], query:"", inputValue:"", abFilter:"", loading:true, abListShow: false, declension:null, availableGenders:{}, stemWords:[], stemList:{}, noResults:false };
  dict!:dict.DictionaryDatabase;
  searchRef = createRef<HTMLDivElement>();
  declensionRef = createRef<HTMLDivElement>();
  
  async componentDidMount() 
  {
    this.dict = new DictionaryDatabaseClass();

    if (typeof window !== "undefined")
    {
      if (this.props.watchNavigation)
        window.addEventListener("popstate", this.handleBrowserNavigation.bind(this), false);

      window.addEventListener("keydown",  this.handleKeyDown.bind(this), false);
    }

    const queryParams = this.getQueryParams();
    this.setState({...this.state, inputValue:queryParams.query})

    await this.dict.init();

    if (queryParams.query == this.state.query && queryParams.dictLang == this.state.dictLang)
      this.setState({...this.state, loading:false});
    else
      this.search(queryParams.query, queryParams.dictLang);

  }

  componentWillUnmount()
  {
    window.removeEventListener("popstate", this.handleBrowserNavigation.bind(this));
    window.removeEventListener("keydown",  this.handleKeyDown.bind(this));
  }

  handleBrowserNavigation()
  {
    const queryParams = this.getQueryParams();
    
    if (queryParams.query != this.state.query || queryParams.dictLang != this.state.dictLang)
    {
      this.search(queryParams.query, queryParams.dictLang);
    }
  }

  handleKeyDown(e:KeyboardEvent)
  {
    if (e.key == "Escape") 
      this.closeDeclension();
  }

  handleClick(e:MouseEvent)
  {
    if (!this.declensionRef?.current?.contains(e.target)) {
      this.closeDeclension();
    }
  }

  UNSAFE_componentWillUpdate(nextProps:DictionarySearchProps)
  {
    if (nextProps.searchText && nextProps.searchText != this.state.inputValue) //externally provided search forcing
      this.search(nextProps.searchText);
  }

  openDeclension(word:string, gender:Gender)
  {
    window.removeEventListener("mousedown",  this.handleClick);
    this.setState({...this.state, declension:{word:word, gender:gender}});
    window.addEventListener("mousedown", this.handleClick.bind(this));
  }

  closeDeclension()
  {
    this.setState({...this.state, declension:null});
    window.removeEventListener("mousedown",  this.handleClick);
  }

  getQueryParams()
  {
    var queryArr = window.location.pathname.split("/").slice(3);
    const dictLang = Object.values(LanguagePairs).includes(queryArr[0] as LanguagePairs) ? queryArr[0] : this.state.dictLang;
    queryArr[1] = decodeURI(queryArr[1] || "")
    const query = queryArr[2] == "slp1" ? Sanscript.t(queryArr[1], "slp1", "iast") : queryArr[1];
    
    return {query:query, dictLang:dictLang};
  }

  startSearch(text:string, dictLang?:string)
  {
    this.props.onNavigate(text, dictLang);
    this.search(text, dictLang);
    
    
    if (this.searchRef?.current)
    {
      this.searchRef.current.scrollIntoView(true);
    }
  }

  async search(text:string, dictLang?:string)
  {
    dictLang = dictLang || this.state.dictLang;
    //document.title = text + " - " + origTitle;
    text = text.trim();
    let origText = text;
    let stemWords:DeclensionWord[] = [];
    if (text != this.state.query)
    {
      text = text.replaceAll("*", "%");
      let caseInsensitive = false;
      if ( dictLang == LanguagePairs.SanskritEnglish )
      {
        let possibleDeclension = isSafari() ? [{word:text, num: "?", case: "?", gen: "?"}] : reverseDeclension(text);
        
        if (possibleDeclension.length > 0)
        {
          let uniqueDeclensions = possibleDeclension;//[...new Map(possibleDeclension.map((item) => [item.word, item])).values(),];

          uniqueDeclensions = uniqueDeclensions.sort((a,b) => levenshteinDistance(a.word, text) < levenshteinDistance(b.word, text) ? -1 : 1);
          
          for (let i = 0; i < uniqueDeclensions.length; i++)
          {
            if (uniqueDeclensions[i].word == text)
              continue;

            let r = await this.dict.search(Sanscript.t(uniqueDeclensions[i].word, "iast", "slp1"), dictLang, {onlyKeys:true, caseInsensitive:caseInsensitive});
            
            if (r.length > 0)
            {
              stemWords.push(uniqueDeclensions[i]);
            }
              
          }

        }

        text = Sanscript.t(text, "iast", "slp1");
      }
      else
      {
        caseInsensitive = true;
      }
    
      let multiResults:SearchResult = await this.dict.search(text, dictLang, {caseInsensitive:caseInsensitive});

      
      if (multiResults.length == 0 && stemWords[0]) //offer first stem result if no result found
      {
        multiResults = await this.dict.search(Sanscript.t(stemWords[0].word, "iast", "slp1"), dictLang, {caseInsensitive:caseInsensitive});
      }

      multiResults = multiResults.sort((r1, r2) => r1.dictLang == dictLang && r2.dictLang != dictLang ? -1 : (DatabaseSources.findIndex(e => e.lang == r1.dictLang) < DatabaseSources.findIndex(e => e.lang == r2.dictLang) ? -1 : 1) );
      let noResults = multiResults.length == 0;
      
      let availableGenders = this.parseResults(multiResults);

      let stemList:{[key: string]:DeclensionWindow[]} = {};

      for (let i = 0; i < stemWords.length; i++)
      {
        if (!stemList[stemWords[i].word])
        {
          stemList[stemWords[i].word] = [];
        }

        stemList[stemWords[i].word].push(stemWords[i]);
      }
      
      this.setState({...this.state, dictLang: dictLang, results:multiResults, query:origText, inputValue:origText, loading:false, availableGenders: availableGenders, stemWords:stemWords, stemList:stemList, noResults:noResults});
      this.props.onChange(origText);
    }
  }

  parseResults(results:SearchResult):{[key: string]:Gender[]}
  {
    let availableGenders:{[key: string]:Gender[]} = {};
    for (var i= 0; i < results.length; i++)
    {
      for (var j = 0; j < results[i].result.length; j++ )
      {
        let key = results[i].dictLang == LanguagePairs.EnglishSanskrit ? results[i].result[j][0] : Sanscript.t(results[i].result[j][0], "slp1", "iast");
        results[i].result[j][0] = key;
        let value = results[i].result[j][1];
        if (!isSafari())
        {
          let parsed = parseGenders(key, value);
          //TODO: overriding object values is not nice. should return a new object, but performance should be measured
          results[i].result[j][1] = parsed.content;
          let genderList = availableGenders.hasOwnProperty(key) ? availableGenders[key] : [];
          parsed.genders.forEach(gender => {if (!genderList.includes(gender)) genderList.push(gender)});
          availableGenders[key] = genderList;
        }
        else
        {
          results[i].result[j][1] = value;
        }
      }
    }

    for(var s in availableGenders)
    {
      availableGenders[s] = availableGenders[s].sort((a,b) => a == "mas" ? -1 : a == "fem" && b == "neu" ? -1 : 1 );
    }

    return availableGenders;
  }

  async searchSuggest(value:string, deep?:boolean):Promise<string[]>
  {
    
    var replacePattern:RegExp;
    var origValue = value;

    if ( this.state.dictLang != LanguagePairs.EnglishSanskrit)
    {
      value = Sanscript.t(value, "iast", "slp1");
      
      var replacePatternText =  value.replaceAll("r", "[rf]");
      replacePatternText = replacePatternText.replaceAll("l", "[lx]");
      replacePatternText = replacePatternText.replaceAll("d", "[dq]");
      replacePatternText = replacePatternText.replaceAll("D", "[DQ]");
      replacePatternText = replacePatternText.replaceAll("t", "[tw]");
      replacePatternText = replacePatternText.replaceAll("T", "[TW]");
      replacePatternText = replacePatternText.replaceAll("n", "[nyr]");
      replacePatternText = replacePatternText.replaceAll("s", "[sz]");
      replacePatternText = replacePatternText.replaceAll("*", "");
      value = value.replaceAll(/[rldDtTns]/g, "_");
      
      replacePattern = new RegExp("^" + replacePatternText, "i");   
    }

    

    let results:SearchResult = await this.dict.search(value + "%", this.state.dictLang, {onlyKeys:true, limit:deep ? 30000 : 3000, caseInsensitive:true});

    var list = [];

    for (var i= 0; i < results.length; i++)
    {
      for (var j = 0; j < results[i].result.length; j++ )
      {
        var key = results[i].result[j][0];
        
        if ( list.indexOf(key) == -1 && (!replacePattern || replacePattern.test(key)) )
          list.push(key);
      }
    }
    
    list = list.sort((a,b) => {return (a.length < b.length ? -1 : (a.length > b.length ? 1 : a.toLowerCase() > b.toLowerCase() ? 1 : -1))});
    list = list.slice(0,15);
    
    if ( this.state.dictLang == LanguagePairs.SanskritEnglish)
        list = list.map(key => {return Sanscript.t(key, "slp1", "iast")});
    
    if (list.length == 0 && !deep)
      return this.searchSuggest(origValue, true);
    else
      return list;
  }

  

  shouldComponentUpdate(nextProps:DictionarySearchProps, nextState:DictionarySearchState):boolean
  {
    return !nextState.loading;
  }
  
  render() 
  {
    return (
      <StyledDictionarySearch>
        <DictionarySearchBox ref={this.searchRef} $isSticky={this.props.isSticky}>
          <LangToggleButton states={langButtonStates} activeState={langButtonStates.findIndex(e => e.key == this.state.dictLang)} onClick={(i) => {this.setState({...this.state, query:"", dictLang:langButtonStates[i].key})}} />
          <AutoSuggest  value={this.state.inputValue} 
                        disabled={this.state.loading}
                        autoIast={this.state.dictLang == LanguagePairs.SanskritEnglish}
                        id={this.props.inputId}
                        autoFocus
                        onGetSuggestion={(v) => this.searchSuggest(v)} 
                        onSearch={(v) => this.startSearch(v, this.state.dictLang)}
                        onChange={(v) => this.setState({...this.state, inputValue: v})} />
          
          <AbSelector name="ab" selected={this.state.abFilter} onChange={(v) => this.setState({...this.state, abFilter: this.state.abFilter == v ? "" : v })} />
        </DictionarySearchBox>
        {!isSafari() && this.state.declension && <DeclensionWindow ref={this.declensionRef} word={this.state.declension.word} gender={this.state.declension.gender} availableGenders={this.state.availableGenders[this.state.declension.word]} onClose={this.closeDeclension.bind(this)} />}
        {/*!isSafari() && this.state.stemWords.length > 0 && this.state.stemWords.map(stem => <StemWord><i>{`${this.props.t("gender-"+stem.gen)} ${this.props.t("case-"+stem.case)} ${this.props.t("number-"+stem.num)}`}</i> - <a href={this.props.uri + "/" + LanguagePairs.SanskritEnglish + "/" + stem.word} onClick={(e) => {e.preventDefault(); this.startSearch(stem.word, LanguagePairs.SanskritEnglish); return false;}} >{stem.word}</a></StemWord>)*/}
        {!isSafari() && this.state.stemWords.length > 0 && Object.keys(this.state.stemList).map((word:string) => 
          <StemWord key={word}>
            <a href={this.props.uri + "/" + LanguagePairs.SanskritEnglish + "/" + word} 
               onClick={(e) => {e.preventDefault(); this.startSearch(word, LanguagePairs.SanskritEnglish); return false;}} >
                {word}
            </a>
            {this.state.stemList[word].map((stem:DeclensionWord, index) => 
              <div key={index}>
                <i>
                  {`${this.props.t("gender-"+stem.gen)} ${this.props.t("case-"+stem.case)} ${this.props.t("number-"+stem.num)}`}
                </i>
              </div>)
            }
          </StemWord>)
        }
        <DictionaryList uri={this.props.uri} 
                        dictLang={this.state.dictLang} 
                        results={this.state.results} search={(text,lang) => {this.startSearch(text, lang)}} 
                        availableGenders={this.state.availableGenders}
                        abFilter={abList.find((ab) => ab.name == this.state.abFilter)}
                        declense={this.openDeclension.bind(this)} />
        {this.state.loading && (<LoadingContainer><LoadingAnim src="/images/loading.gif" alt="loading... " width="110" height="110" /></LoadingContainer>)}
        {this.state.query != "" && this.state.noResults && <NoResults>{this.props.t("noresults")}</NoResults>}
      </StyledDictionarySearch>
    );
  }
}

const StyledDictionarySearch = styled.div`
  max-width: 1000px;
  margin: auto;
`;

const LangToggleButton = styled(MultiToggleButton)`
  padding: 0.2em 1em;
  margin-bottom: 0.6em;
  text-transform: none;
  height: 1.8em;
  color: ${(props) => props.theme.colors.red};
  @media print { display: none; }
`;

const abList = [
  {name:"abl"},
  {name:"acc"},
  {name:"comp", alt:"ifc"},
  {name:"dat"},
  {name:"gen"},
  {name:"ind"},
  {name:"inf"},
  {name:"instr"},
  {name:"loc"},
  {name:"nom"},
  {name:"√"},
];

const AbSelector = (props:{name:string, selected:string | null, onChange:(value:string)=>void}) =>
{
  const { t } = useTranslation();
  const [state, setState] = useState(typeof window !== "undefined" ? window.localStorage.getItem("showCases") == "true" : false);
  const saveState = (state:boolean) => { window.localStorage.setItem("showCases", state); setState(state); };
  
  return (
  <AbSelectorContainer>
    {[<AbShowCasesButton key="showcases" onClick={() => saveState(!state)}>{state ? t("hide-cases") : t("show-cases")}</AbShowCasesButton>]
      .concat(state ? abList.map((ab, index) => (<AbRadioButton key={index} name={props.name} value={ab.name} selected={props.selected == ab.name} onSelect={(v) => props.onChange(v)}>{ab.name}</AbRadioButton>))
                    : [])}
  </AbSelectorContainer>);
}


const DictionarySearchBox = styled.div<{$isSticky:boolean}>`
  position: ${(props) => props.$isSticky ? "sticky" : "block"};
  top: ${(props) => props.$isSticky ? "56px" : "0"};
  background-color: ${(props) => props.theme.colors.background};
  padding-top: 10px;
  padding-bottom: 5px;
  text-align: left;
`;

const LoadingContainer = styled.div`
  text-align: center;
  width: 100%;
`;

const LoadingAnim = styled.img`
  display: inline;
`;


const AbShowCasesButton = styled(Button)`
  height: 1.5em;
  text-transform: none;
  padding: 0.2em 0.7em;
  line-height: 1em;
  letter-spacing: 0;
  margin-right: 0.8em;
  margin-top: 0.45em;
  min-width: 5.7em;
  font-size: 0.7em;
  @media print { display: none; }
`;

const AbSelectorContainer = styled.div`
  display: flex;
  gap: 0.3em;
  align-items: stretch;
  margin-bottom: 0.8em;
  flex-wrap: wrap;
  @media print { display: none; }
`;

const AbRadioButton = styled(RadioButton)`
  & label {
    height: 1.5em;
    text-transform: none;
    padding: 0.2em 0.7em;
    line-height: 1em;
    letter-spacing: 0;
    font-weight: normal;
    font-size: 0.7em;
  }
`;

const NoResults = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  text-align: center;
  font-size: 0.9em;
`;

const StemWord = styled.div`
  background-color: ${(props) => props.theme.colors.lightgray};
  font-size: 0.8em;
  margin-bottom: 5px;
  text-align: center;
`;

export default withTranslation()(DictionarySearch);
