import styled from "styled-components";


const Row = styled.div`
  max-width: 1800px;
  box-sizing: border-box;
  margin-left: 5%;
  margin-right: 5%;
  text-align: left;
`;

const ContentRow = styled(Row)`
  position: relative;
`;

export { Row, ContentRow };
