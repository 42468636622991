import styled from "styled-components";
import React, {useState} from "react";
import { useTranslation } from "react-i18next"
import { CategoryFilter } from "../category-filter";
import { Gender } from "../../grammar/types";
import { Case, Rule } from "../../grammar/declension";
import { sandhiParser } from "../../grammar/sandhi";
import { declensionRules } from "../../grammar/declension-rules";

const lexPattern = new RegExp('(?:(?:<lex(?: type="nwh")?>)|(?:<div n="1">))(.+?)<\/(?:lex|div)>', "gm");

const genderList:Array<{name:Gender, title:string}> = [
    {name: "mas", title:"Masculine"},
    {name: "fem", title:"Feminine"},
    {name: "neu", title:"Neuter"},
];


const DeclensionTable = (props:{match:RegExpMatchArray, cases:Array<Case>, rule:Rule, gender:Gender}) =>
{
    const { t } = useTranslation();

    return <StyledDeclensionTable>
        <tbody>
            <tr>
                <THead></THead>
                <THead>{t("singular")}</THead>
                <THead>{t("dual")}</THead>
                <THead>{t("plural")}</THead>
            </tr>
            { 
                props.cases.map((c:Case, index) =>
                {
                    return <tr key={index}>
                            <THead className="case">{c.name}.</THead>
                            <td>{c.s instanceof Array ? c.s.map((cur) => sandhiParser(props.match[1], cur, c.name, "s", props.gender, props.match[0]).filter((v, index, self) => self.indexOf(v) === index).join("/")).join("/") : sandhiParser(props.match[1], c.s, c.name, "s", props.gender, props.match[0]).filter((v, index, self) => self.indexOf(v) === index).join("/")}</td>
                            <td>{c.d instanceof Array ? c.d.map((cur) => sandhiParser(props.match[1], cur, c.name, "s", props.gender, props.match[0]).filter((v, index, self) => self.indexOf(v) === index).join("/")).join("/") : sandhiParser(props.match[1], c.d, c.name, "d", props.gender, props.match[0]).filter((v, index, self) => self.indexOf(v) === index).join("/")}</td>
                            <td>{c.p instanceof Array ? c.p.map((cur) => sandhiParser(props.match[1], cur, c.name, "s", props.gender, props.match[0]).filter((v, index, self) => self.indexOf(v) === index).join("/")).join("/") : sandhiParser(props.match[1], c.p, c.name, "p", props.gender, props.match[0]).filter((v, index, self) => self.indexOf(v) === index).join("/")}</td>
                        </tr>;
                })
            }
        </tbody>
    </StyledDeclensionTable>;
}

export const DeclensionWindow = React.forwardRef((props:{word:string, gender:Gender, availableGenders:Gender[], onClose:()=>void}, ref) => {
  const { t } = useTranslation();
  let match:RegExpMatchArray | null = null;
  let rule:Rule|undefined;
  let filteredGenders:Array<{name:Gender, title:string}> = [];
  let gender = props.gender;
  const [genderState, setGenderState] = useState(gender);

  for (var i =0; i<declensionRules.length; i++)
  {
    rule = declensionRules[i];
    match =props.word.match(new RegExp(rule.pattern));
    if (match)
    {
        let keys:Gender[] = Object.keys(rule.replace) as Gender[];
        if (!rule.replace.hasOwnProperty(props.gender))
            gender = keys[0];
        
        filteredGenders = genderList.filter((v) => keys.includes(v.name));
        break;
    }      
  }
  

  return (
    <StyledDeclension ref={ref}>
        <CloseButton onClick={props.onClose} className="icon-cancel" />
        <Word>{props.word}</Word>
        <GenderSelector name="gender" categoryList={filteredGenders.map(gen => !props.availableGenders?.includes(gen.name) ? {...gen, disabled:true} : gen)} defaultState={genderState} onChange={(v:Gender) => setGenderState(v)} />
        { (match && rule != undefined) ? <DeclensionTable match={match} cases={rule?.replace[genderState] !== undefined ? rule.replace[genderState]! : []} gender={genderState} rule={rule} /> : t("no-declension") }
    </StyledDeclension>
  );
});

export const parseGenders = (word:string, content:string):{genders:Gender[], content:string} =>
{
    const lexGenderPattern = new RegExp("(?<=(?:[^\\w]|^)m?f?)([mfn]\\.?)(?=f?n?(?:[^\\w\\=]|$))", "gm");
    let genders:Gender[] = [];
    content = content.replaceAll(lexPattern, (_, matchGroup:string) => { 

        return `<lex>${matchGroup.replaceAll(lexGenderPattern, (_, genMatch) => {

            let genderName:Gender | undefined;
            switch(genMatch[0])
            {
            case "m": genderName = "mas"; break;
            case "f": genderName = "fem"; break;
            case "n": genderName = "neu"; break;
            }
            
            if (genderName && !genders.includes(genderName) ) {
                genders.push(genderName);
            }
            return `<a class="gender" data-word="${word}" href="#${genMatch[0]}">${genMatch}</a>`;
        })
    }</lex>`
    });

    return {genders:genders, content:content};
};

const StyledDeclension = styled.div`
  position: fixed;
  text-align: center;
  z-index: 1000;
  top: 50%;
  left: 50%;
  min-width: 300px;
  min-height: 300px;
  transform: translate(-50%, -50%);
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.gray};
  padding: 5px;
  box-shadow: 0px 2px 2px rgb(0 0 0 / 30%);
`;

const Word = styled.div`
    text-align: center;
    font-weight: bold;
    margin-top: 15px
`

const StyledDeclensionTable = styled.table`
  font-size: 0.7em;
  margin: 10px auto 25px auto;
  width: 100%;
  
  td {
      padding: 3px;
      white-space: nowrap;
  }
`;

const THead = styled.td`
    font-weight: bold;
    text-transform: capitalize;
`;

const CloseButton = styled.a`
  position: absolute;
  right: 20px;
  top: 13px;
  display: block;
  font-weight: bold;
  cursor: pointer;
`;

const GenderSelector = styled(CategoryFilter)`
  margin-top: 15px;
  justify-content: center;
`;