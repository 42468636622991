function setStyle(list:HTMLCollectionOf<Element>, style:string, add:boolean)
{
  Array.from(list).forEach((e:Element) =>
  {
    if (add)
      e.classList.add(style);
    else
      e.classList.remove(style);
  })
}

function saveState(sign:string)
{
    window.localStorage.setItem("sign", sign);
}

var closeToC:() => void;

export const setCloseToC = (v:() => void) =>
{
  closeToC = v;
}

export const saveRecentlyRead = (title:string, subtitle:string, author:string, collection:string, slug:string, locale:string) =>
{
  if (typeof window !== "undefined")
  {
    
    var last = JSON.parse(window.localStorage.getItem("recentlyRead")) || [];
    var url = location.pathname;
    
    var existing = last.findIndex(e => e.slug == slug && e.collection == collection );
    
    if (existing > -1)
    {
      var refresh = last.splice(existing, 1)[0];
      refresh.date = Date.now();
      last.unshift(refresh);
    }
    else
    {
      last.unshift({
        collection: collection,
        slug: slug,
        title: title,
        subtitle: subtitle,
        author: author,
        date: Date.now(),
        locale: locale
      });

      if (last.length > 5)
        last.pop();
    }
    window.localStorage.setItem("recentlyRead", JSON.stringify(last));
  }
}

export const initState = () =>
{

  changeSign(getSignState());
  if (getState("iast"))
    toggleHide(["iast", "iast-comment", "chumma-iast"], true, false);
  if (getState("iast-red"))
    toggleHide(["iast-red", "par"], true, false);
  if (getState("trans-iast"))
    toggleHide(["trans-iast", "trans-iast-comment", "chumma-trans", "explanation", "quote-iast", "quote-trans-iast"], true, false);

  createToC();
  setCommentarySecondary();
}

export const getSignState = ():"dev"|"sharada"|"shardev"|"off" =>
{
    if (typeof window === "undefined")
        return "sharada";
    else
        switch (window.localStorage.getItem("sign"))
        {
          case "shardev":
            return "shardev";
          break;
          case "dev":
              return "dev";
          break;
          case "off":
              return "off";
          break;
          default:
              return "sharada";
        }
}

export const changeSign = (sign:string, commentaryDisabled:boolean = false) =>
{
  switch (sign)
  {
    case "shardev": 
      setStyle(document.getElementsByClassName("dev"), "hidden", false);
      setStyle(document.getElementsByClassName("dev-comment"), "hidden", false || commentaryDisabled);
      setStyle(document.getElementsByClassName("sharada"), "hidden", false);
      setStyle(document.getElementsByClassName("sharada-comment"), "hidden", false);
      setStyle(document.getElementsByClassName("chumma-sharada"), "hidden", false);
    break;

    case "dev": 
      setStyle(document.getElementsByClassName("dev"), "hidden", false);
      setStyle(document.getElementsByClassName("dev-comment"), "hidden", false || commentaryDisabled);
      setStyle(document.getElementsByClassName("sharada"), "hidden", true);
      setStyle(document.getElementsByClassName("sharada-comment"), "hidden", true);
      setStyle(document.getElementsByClassName("chumma-sharada"), "hidden", false);
    break;

    case "off":
      setStyle(document.getElementsByClassName("dev"), "hidden", true);
      setStyle(document.getElementsByClassName("dev-comment"), "hidden", true);
      setStyle(document.getElementsByClassName("sharada"), "hidden", true);
      setStyle(document.getElementsByClassName("sharada-comment"), "hidden", true);
      setStyle(document.getElementsByClassName("chumma-sharada"), "hidden", true);
    break;

    default:
      setStyle(document.getElementsByClassName("dev"), "hidden", true);
      setStyle(document.getElementsByClassName("dev-comment"), "hidden", true);
      setStyle(document.getElementsByClassName("sharada"), "hidden", false);
      setStyle(document.getElementsByClassName("sharada-comment"), "hidden", false || commentaryDisabled);
      setStyle(document.getElementsByClassName("chumma-sharada"), "hidden", false);
  } 

  saveState(sign);
  setCommentarySecondary();
}

export const getState = (type:string) =>
{
  if (typeof window === "undefined")
    return false;
  else 
    return (window.localStorage.getItem(type) == "true");
}

export const setCommentarySecondary = () => 
{
  const isSecondary = getState("iast") && getSignState() == "off";
  console.log("isSecondary", isSecondary, getState("iast"), getSignState());
  Array.from(document.querySelectorAll('[class^="trans-iast-comment"]')).forEach(e =>
    isSecondary ? e.classList.add("italic") : e.classList.remove("italic"));
}

export const toggleHideItems = (type:string, isHidden:boolean) =>
{
  Array.from(document.querySelectorAll('[class^="'+type+'"]')).forEach(e =>
    isHidden ? e.classList.add("hidden") : e.classList.remove("hidden"));
}

export const toggleHide = (type:string[], isHidden:boolean, isSave:boolean = true) =>
{
  type.forEach(t => toggleHideItems(t, isHidden));
  if (isSave)
    window.localStorage.setItem(type[0], isHidden ? "true" : "false");

  setCommentarySecondary();
}

export const clearToC = () =>
{
  let toc = document.getElementById("tocContent");
  toc && (toc.innerHTML = "");
}

function createToC()
{
  var toc = document.getElementById("tocContent");
  var list = document.querySelectorAll("p[class*=MsoToc]")
  var anchors = document.querySelectorAll("p[class=dev] a[name*=_Toc]")

  if (toc)
  {
    toc.innerHTML = "";

    for (var i = 0; i < list.length; i++ )
    {
      var elem = list[i];
      
      toc.appendChild(elem);
      elem.onclick = function() 
      {
        if (window.innerWidth <= 550 )
        {
          closeToC();
        }
      }
    }

    
    //document.getElementById("tocContent")?.innerHTML = "";

    
    for (var i = 0; i < anchors.length; i++ )
    {
      var elem = anchors[i];
      elem.parentNode.parentNode.parentNode.insertBefore(elem, elem.parentNode.parentNode);
    }

    if (toc.children.length > 0 )
    {
      document.getElementById("tocTitle").classList.remove("hidden");
    }
  }
  
}