module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-axe/gatsby-browser.js'),
      options: {"plugins":[],"showInProduction":false,"axeOptions":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-theme-i18n/gatsby-browser.js'),
      options: {"plugins":[],"defaultLang":"en","prefixDefault":true,"configPath":"/builds/zubak.balazs/mahanaya/i18n/config.json"},
    },{
      plugin: require('../node_modules/gatsby-theme-i18n-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"locales":"./i18n/react-i18next","i18nextOptions":{"ns":["translation"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-HBML7HXMWN"],"gtagConfig":{"anonymize_ip":false,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Mahānaya","short_name":"Mahānaya","start_url":"/?app-home","scope":"/","background_color":"#ffffff","theme_color":"#900000","theme_color_in_head":false,"display":"standalone","prefer_related_applications":false,"icons":[{"src":"/favicons/android-chrome-192x192.png","sizes":"192x192","type":"image/png","purpose":"any maskable"},{"src":"/favicons/android-chrome-512x512.png","sizes":"512x512","type":"image/png","purpose":"any"},{"src":"/favicons/apple-touch-icon.png","sizes":"180x180","type":"image/png","purpose":"any"},{"src":"/favicons/favicon-32x32.png","sizes":"32x32","type":"image/png","purpose":"any"},{"src":"/favicons/favicon-16x16.png","sizes":"16x16","type":"image/png","purpose":"any"}],"legacy":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
