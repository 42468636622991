import React, { ReactNode, useState } from "react"
import styled from "styled-components"

export const ResizeableWindow = React.forwardRef((props:{children:ReactNode, isOpen:boolean}, ref) =>
{
    const [size, setSize] = useState(typeof window !== "undefined" && window.localStorage.getItem("floatingDictSize") ? parseInt(window.localStorage.getItem("floatingDictSize")!) : 250);
    let saveTimer:NodeJS.Timeout|null = null;

    const onResize = (e:MouseEvent|TouchEvent) =>
    {
        e.preventDefault();
        
        let y = 0;
        if (e instanceof MouseEvent)
            y =  e.y
        else 
        {
            y = e.touches[0].clientY;
        }
            

        const newSize = Math.max(20, Math.min(window.innerHeight * 2 / 3, window.innerHeight - y));
        setSize(newSize);
        if (saveTimer)
            clearTimeout(saveTimer);
        saveTimer = setTimeout(() => window.localStorage.setItem("floatingDictSize", newSize.toString()), 1000);
    }

    const startDragging = () =>
    {
        document.addEventListener("mouseup", stopDragging);
        document.addEventListener("touchend", stopDragging);
        document.addEventListener("mousemove", onResize);
        document.addEventListener("touchmove", onResize, {passive: false});

        return false;
    }

    const stopDragging = () =>
    {
        document.removeEventListener("mousemove", onResize);
        document.removeEventListener("touchmove", onResize);
    }

    return <StyledResizeableWindow ref={ref} size={size} $isOpen={props.isOpen}>
        <Resizer onMouseDown={() => startDragging()} onTouchStart={() => startDragging()} className="icon-minus" />
        {props.children}
    </StyledResizeableWindow>;
});

const StyledResizeableWindow = styled.div<{size:number, $isOpen:boolean}>`
    height: ${(props) => (props.$isOpen ? props.size : 0) + "px" };
    position: fixed;
    display: ${(props) => props.$isOpen ? "block" : "block"};
    bottom:  ${(props) => props.$isOpen ? 0 : "-50px"};
    width: 100%;
    z-index: 50;
    padding: 15px;
    background-color: ${(props) => props.theme.colors.white};
    box-shadow: #00000054 0px 0px 6px;
    border-radius: 12px;
    transition: height 0.07s, bottom 0.07s;
    @media print { display: none; }
`;

const Resizer = styled.div`
    width: 100%;
    height: 20px;
    position: absolute;
    top: 0;
    cursor: ns-resize;
    display: ${(props) => props.theme.colors.gray};
`;