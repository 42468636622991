import { internalSandhiRules } from "./sandhi-rules";
import { CaseName, Gender, Num } from "./types";

export type SandhiRule = {
    name:string, 
    parse:(a:string, b:string, cas:CaseName, num:Num, gen:Gender, origA:string, origB:string, fullWord:string) => {a:string, b:string}[], 
    reverse:(word:string) => string[]
};


export const sandhiParser = (a:string, b:string, cas:CaseName, num:Num, gen:Gender, fullWord:string) =>
{
    let possibleOutputs = [{a:a, b:b}];
    internalSandhiRules.forEach(rule => { 
        possibleOutputs.forEach((ab,i) => {
            const newAbs = rule.parse(ab.a,ab.b, cas, num, gen, a, b, fullWord); /*console.log(ab, rule);*/
            //newAbs.find(newAb => newAb.a != ab.a || newAb.b != ab.b) && console.log(rule.name, newAbs);
            possibleOutputs[i] = newAbs[0];
            if (newAbs.length > 1)
                possibleOutputs = possibleOutputs.concat(newAbs.splice(1));
        })
     } );
    return possibleOutputs.map(ab => ab.a + ab.b);
}

export const sandhiReverser = (word:string) =>
{
    let possibleWords:string[] = [word];
    
    for (let i = internalSandhiRules.length -1; i >= 0; i--)
    {
        let rule = internalSandhiRules[i];
        possibleWords = possibleWords.concat(possibleWords.length == 0 ? rule.reverse(word) : possibleWords.map(w => {
            let reversed = rule.reverse(w);
            //reversed.find(r => r != word) && console.log(rule.name, reversed); //figure out who modified
            return reversed;
        }).flat()).filter((v, index, self) => self.indexOf(v) === index);
    }
    
        
    return possibleWords;
}

