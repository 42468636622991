import React, { Ref, useEffect } from "react";
import styled from "styled-components";
import Sanscript from "@sanskrit-coders/sanscript";

import {SearchResult} from '../../utils/dictionary-database';
import { LanguagePairs } from "../../utils/dictionary-data";
import { Gender } from "../declension/declension-window";


const headerPattern = /.*?<body>(.*?)<\/body>.*/s;
const seePattern = /<s>(.*?)<\/s>/gm
const seeListPattern = /(?:, ?)| /gm
const see1Pattern = /<s1 slp1="(.*?)">(.*?)<\/s1>/gm

const listUpdate = (search:(text:string, lang?:string) => void, declense:(word:string, gender:Gender) => void, abFilter?:{name:string, alt?:string}) =>
{
  // TODO: resolve lot of updates
  var seeList = Array.from(document.getElementsByClassName("s"));
  seeList.forEach(see => {
    
    let seeContent = see.textContent || "";
    let attr = see.getAttribute("slp1");
    let slp1 = attr != null ? attr : seeContent;
    
    if ( !attr )
        see.textContent = Sanscript.t(seeContent, "slp1", "iast");
    
    see.onclick = (e) =>
    { 
        let trans = slp1;
        //if ( trans.indexOf("-" + key[0] + "°") != -1 )
        //    trans = Sanscript.t(trans.substr(0,trans.length - 3) + key, "slp1", "iast");
        //else
        trans = Sanscript.t(trans.replaceAll(/[^a-zāīūṝḹḥṁṃṛḷḍṭṅñṇṣś]/mgi, ""), "slp1", "iast");
            
        search(trans, LanguagePairs.SanskritEnglish);
        return false;
    };
  });

  var genderList = Array.from(document.getElementsByClassName("gender"));
  genderList.forEach(gender => {
    
    let genderName:Gender = "mas";
    let word = gender.getAttribute("data-word");

    switch(gender.getAttribute("href")?.substring(1,2))
    {
      case "m": genderName = "mas"; break;
      case "f": genderName = "fem"; break;
      case "n": genderName = "neu"; break;
    }
    
    gender.onclick = (e) =>
    { 
        if (word)
        declense(word, genderName);
        return false;
    };
  });

  if (abFilter)
  {
    const pattern = new RegExp(`(?:^|[^\p{L}])${abFilter.alt ? `(?:${abFilter.name}|${abFilter.alt})` : abFilter.name}([^\p{L}]|$)`, "imu");
    
    let abList = Array.from(document.getElementsByTagName("ab"));
    abList.forEach(ab =>
    {
      if ( ab.textContent && (pattern.test(ab.textContent)) )
      {
        ab.classList.remove("dict-unhighlight");
        ab.classList.add("dict-highlight");

        var par = ab.parentElement;
        while (par && par.nodeName != "TR")
        {
            par = par.parentElement;
        }
        if (par)
          par.classList.add("highlight-row"); 
      }
      else
      {
        ab.classList.remove("dict-highlight");
        ab.classList.add("dict-unhighlight");
      } 
    });
  }
  else
  {
      Array.from(document.getElementsByTagName("ab")).forEach(e => e.classList.remove("dict-unhighlight", "dict-highlight"));
  }
}

const unmount = () =>
{
  var genderList = Array.from(document.getElementsByClassName("gender"));
  genderList.forEach(gender => {
    gender.onclick = undefined;
  });
}

export const DictionaryList = (props:{results:SearchResult, availableGenders:{[key: string]:Gender[]}, search:(text:string, lang?:string) => void, abFilter?:{name:string, alt?:string}, dictLang:string, uri:string, declense:(word:string, gender:Gender) => void}) =>
{
  useEffect(() => {
    listUpdate(props.search, (word, genderName) => props.declense(word, genderName), props.abFilter);
    return () => unmount(); 
  });
  
  return (
      <ResultTable>
        <tbody>
          {props.results.length > 0 && 
            props.results.map((resultDict:{dictName:string, result:string[]}) =>
            {
              let counter = 0;
                let dbList = resultDict.result.map((result:string[]) =>
                {
                  let word = result[0];
                  var content:string = result[1];
                  content = content.replace(headerPattern, "$1");
                  content = content.replaceAll("√", "<ab>√</ab>");
                  
                  content = content.replaceAll(seePattern,  (_, g1) => g1.split(seeListPattern).map( e=> `<a href="${props.uri}/${props.dictLang}/${e}/slp1" class="s">${e}</a>`).join(", "));
                  content = content.replaceAll(see1Pattern, `<a href="${props.uri}/${props.dictLang}/$1/slp1" slp1="$1" class="s">$2</a>`);

                  return (<tr key={resultDict.dictName +"_" + counter++} className={`${props.abFilter ? "highlight-table " : ""}`}>
                    <ResultKey onClick={() => {if(props.availableGenders[result[0]]) {props.declense(word, props.availableGenders[result[0]][0]);} return false;}}>{word}</ResultKey>
                    <ResultValue dangerouslySetInnerHTML={{__html: content}}></ResultValue>
                  </tr>)
                });
                if ( resultDict.result.length > 0 )
                  dbList.unshift(<tr key={resultDict.dictName}><ResultDictTitle colSpan={2}>{resultDict.dictName}</ResultDictTitle></tr>);
                return dbList;
            })}
        </tbody>
      </ResultTable>
  );
};

const ResultDictTitle = styled.td`
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
  padding: 0.6em;
`;

const ResultTable = styled.table`
  border-collapse: collapse;
  font-size: .78em;
  width: 100%;
  & tr {
    border-bottom: 1px solid ${props => props.theme.colors.bright}
  }

  & tr:not(.highlight-row).highlight-table
  {
    display: none;
  }

  & .dict-unhighlight {
    color: inherit !important;
    font-size: inherit !important;
  }

  & .dict-highlight {
    font-size: 1.7em;
  }
`;

const ResultKey = styled.td`
  vertical-align: top;
  padding: 3px 30px 3px 0;
  text-align: left;
  font-weight: bold;
  cursor: pointer;
  color: ${(props) => props.theme.colors.red};
`;

const ResultValue = styled.td`
  padding: 3px 0 3px 0;
  text-align: left;
  & h1 {
    font-size: 0.78em;
  };
  & s, s1, .s {
    text-decoration: none;
    font-weight: bold;
    color: ${(props) => props.theme.colors.red};
    cursor:pointer;
  };
  & lex a {
    color: orange;
    font-weight: bold;
    padding-right: 2px;
    padding-left: 3px;
    cursor: pointer;
  };
  & ab {
    font-weight: bold;
    cursor: pointer;
    color: #006ef7;
  };
  & ls {
    font-style: italic;
    color: #707070;
  };
  & etym {
    color: #b85900;
  };
  & sq {
    color: #1d8607;
  };
  & hom {
    font-style: italic;
    font-weight: bold;
  };
  & div[n="lb"], div[n="P"] {
    display: inline;
  };
`;