import IDBCache from '@drecom/idb-cache';
import initSqlJs, {SqlJsStatic, Database, QueryExecResult} from 'sql.js';
import {DatabaseSources, LanguagePairs} from './dictionary-data';

export type SearchResult =
Array<{
    dictName:string;
    dictLang:string;
    result:Array<string>;
}>;

export class DictionaryDatabase
{
    idbc!:IDBCache; 
    SQL!:SqlJsStatic;
    dbList:Database[] = [];
    dbAb!:Database;
    dbSources = DatabaseSources;

    constructor()
    {
        
    }

    public async init()
    {
        this.idbc = this.initCache();
        this.SQL = await initSqlJs({locateFile: file => `/sql.js/${file}`});
        this.dbList = await Promise.all(this.dbSources.map(s => this.loadDb(s.id, "/dict/" + s.id + ".sqlite")));
        this.dbAb = await this.loadDb("mwab", "/dict/mwab.sqlite");
    } 

    public async search(key:string, dictLang:string, options:{onlyKeys?:boolean, limit?:number, order?:string, caseInsensitive?:boolean} = {}):Promise<SearchResult>
    {
        
        options = options ? options : {};

        var keys = options.onlyKeys ? "key" : "key, data";
        var limit = options.limit ? options.limit : 100;
        var order = options.order ? " ORDER BY " + options.order : "";
        var caseInsensitive = "PRAGMA case_sensitive_like = " + (options.caseInsensitive ? 0 : 1) + "; ";
        var wholeWordPattern = new RegExp("[^a-z]" + key + "[^a-z]", "i");

        var result = [];
        for (var i = 0; i < this.dbList.length; i++ )
        {
            var queryResult:QueryExecResult[];

            if (this.dbSources[i].lang == dictLang)
            {
                //console.log(caseInsensitive + "SELECT " + keys + " FROM " + this.dbSources[i].id + " WHERE key LIKE '" + key + "'" + order + " LIMIT " + limit);
                queryResult = this.dbList[i].exec(caseInsensitive + "SELECT " + keys + " FROM " + this.dbSources[i].id + " WHERE key LIKE '" + key + "'" + order + " LIMIT " + limit);
                
            }
            else if (dictLang == LanguagePairs.EnglishSanskrit && !options.onlyKeys) 
            {
                //showing sanskrit dictionary value results for english search
                queryResult = this.dbList[i].exec(caseInsensitive + "SELECT " + keys + " FROM " + this.dbSources[i].id + " WHERE data LIKE '%" + key + "%'" + order + " LIMIT 100");
                //console.log(caseInsensitive + "SELECT " + keys + " FROM " + this.dbSources[i].id + " WHERE data LIKE '%" + key + "%'" + order + " LIMIT 100");
                
                var count = 0;
                if (queryResult && queryResult[0] && queryResult[0].values.length)
                {
                    //console.log(queryResult[0].values[0], wholeWordPattern.test(queryResult[0].values[0][2]));
                    queryResult[0].values = queryResult[0].values.filter(e => count++ <= limit && wholeWordPattern.test(e[1]));
                    
                    if (queryResult[0].values.length == 0)
                        queryResult = [];
                }

            }
            else
            {
                queryResult = [];
            }

            if (queryResult.length > 0 )
                result.push({dictName: this.dbSources[i].name, dictLang: this.dbSources[i].lang, result: queryResult.length > 0 ? queryResult[0].values : []});
        }

        return result;
    }

    initCache()
    {
        return new IDBCache('dictionaryCache', {
            size : 524288000, // Size limit (Default 50MB)
            count : 100, // Number of files limit (Default 100)
            defaultAge : 8640000, // max-age when there is no setting (Default 1day)
        }); 
    }

    async loadDb(key:string, file:string )
    {
        let cache;
        try
        {
            cache = await this.idbc.get(key);
        }
        catch (e) {}
        
        let dataPromise;
        if (cache)
            dataPromise = cache;
        else
        {
            dataPromise = await fetch(file).then(res => res.arrayBuffer());
            this.idbc.set(key, dataPromise);
        }
            
        var db = new this.SQL.Database(new Uint8Array(dataPromise));
        //db.exec("PRAGMA case_sensitive_like = true;");
        return db;
    }

}