import React, {ReactChildren, useEffect, ReactNode, useState} from "react";
import styled, { ThemeProvider } from "styled-components";
import { themeList, GlobalStyles } from "../../styles";
import { withTranslation, WithTranslation } from "react-i18next";
import {media} from "../../styles/theme";
import { navigate, StaticQuery, useStaticQuery, graphql } from "gatsby";
import { toBlob } from 'html-to-image';
import { copyBlobToClipboard } from 'copy-image-clipboard';
import Toast from "awesome-toast-component";
import { UserContext } from "../../state/usercontext";



// Components
import { Anniversary } from "../anniversary/anniversary";
import { Logo } from "../logo";
import { Footer } from "../footer";
import { Sidebar, SidebarToggle } from "../sidebar";
import { Nav } from "../nav/nav";
import { ContentRow } from "../row";
import { LangSwitcher } from "../langswitcher";
import { PageQuery, Post } from "../../types/posts";
import { setCloseToC, clearToC } from "../sidebar/scripture-text-manager";
import { CategoryFilter } from "../category-filter";
import { getChange, getCurrentPosition, getSunriseAndSunset, isMobile } from "../../utils/utils";

import Sanscript from "@sanskrit-coders/sanscript";
import DictionarySearch from "../dictionary/dictionary-search";
import { ResizeableWindow } from "../resizeable-window/ResizeableWindow";
import { User, getUser, login, logout} from "../../utils/auth";

Sanscript.defaults.preferred_alternates = { itrans : { "A" : "aa", "I" : "ii", "U" : "uu", "j~n" : "GY" } };

const iastPattern = /^iast\-red/;
const scriptureNodePattern = /iast|chumma|sharada|dev/;

const defaultFontSize = 14;

const Container = styled.main<{fontSize:number}>`
  text-align: center;
  padding-top: 2em;
  min-height: 48.6vh;
  box-sizing: content-box;
  position: relative;
  font-size: ${(props) => props.fontSize + "px"};
  background: ${(props) => props.theme.backgroundImage};
  background-position-x: center;
  background-position-y: top;
  background-repeat: no-repeat;
  background-size: 715px;
  ${media.mobile`
    background-size: 100vw;
  `}

  opacity: 1;

  
  @media print {
    background: none;
  }
`;

const Header = styled.header`
  text-align: center;
  background-color: ${(props) => props.theme.colors.bright};
  position: relative;
  z-index: 101;

`;

const HeaderBg = styled.div<{$start:boolean}>`
  @keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 0.7;}
  }
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.headerImage};
  z-index: -1;
  background-size: cover;
  background-position-x: center;
  background-position-y: bottom;
  background-repeat: no-repeat;
  opacity: 0;
  ${(props) => props.$start ? "animation: fadeIn 3s;" : ""}
  -webkit-animation-fill-mode: forwards;
  @media print { display: none; }
`;

const DictionaryResizeableWindow = styled(ResizeableWindow)`
  @media print { display: none; }
`;

const DictSearch = styled.div`
  overflow-y: scroll;
  height: 100%;
`;

const CloseButton = styled.a`
  position: absolute;
  padding: 10px;
  margin: 10px;
  right: 0px;
  top: 0px;
  display: block;
  font-weight: bold;
  cursor: pointer;
`;

const TranslationList = styled(CategoryFilter)`
  margin-top: -40px;
  ${media.mobile`
      margin-top: 15px;
      margin-right: 25px;cr
  `}
`;


class Layout extends React.Component<WithTranslation & {path:string, uri:string, pageContext:{locale:string}, data:PageQuery & {translations:{nodes:{fields:{locale:string, encrypted:boolean}}[]},markdownRemark?:{frontmatter: {title:string}, fields:{collection:string, locale:string}}}, children:ReactNode, navContent?:ReactNode}>
{
  state;
  dictRef = React.createRef<typeof ResizeableWindow>();
  swipeStart: number = 0;

  constructor(props:any)
  {
    super(props);
    let d = new Date();
    let anniversary = d.getMonth() == 9 && d.getDate() >= 15 && d.getDate() < 22 ? d.getFullYear() - 2020 : null;
    
    let theme = typeof window !== "undefined" ? parseInt(window.localStorage.getItem("theme") || "0") : 0;
    let fontSize = typeof window !== "undefined" ? parseInt(window.localStorage.getItem("fontSize") || defaultFontSize.toString()) : defaultFontSize;

    this.state = {sidebarOpen: false, user:{loading: true, user:undefined}, theme: theme, anniversary: anniversary, dictionaryOpen: false, dictionaryText: "", sunset: null, sunrise: null, fontSize: fontSize};
    this.init();
  }

  async init() // for async inits
  {
    Promise.allSettled([
      this.state.theme == 2 ? getSunriseAndSunset() : Promise.resolve({}),
      getUser()
      .then(user => {return Promise.resolve({user:{loading:false, user: user}})})
      .catch(() => Promise.resolve({user:{loading:false, user:null}}))
    ])
    .then((results) => {
      let mergedResult = {};
      results.forEach((result) => {
        if (result.status == "fulfilled")
          mergedResult = {...mergedResult, ...result.value};
      });

      this.setState({...this.state, ...mergedResult});
    });
  }


  async componentDidMount()
  {
    setCloseToC(() => { this.onClose() });

    if ( typeof window !== "undefined" )
    {
      window.addEventListener("keydown", this.shortcutHandler.bind(this));
      document.addEventListener("selectionchange", this.getSelection.bind(this));
      document.addEventListener("click", this.checkOpenDict.bind(this));
    }

    this.addCopyListener();
  }

  componentWillUnmount()
  {
    window.removeEventListener("keydown", this.shortcutHandler.bind(this));
  }
  
  componentDidUpdate(prevProps:any, prevState:any) { 
    if ( window.location.pathname != "/" )
      window.localStorage.setItem("lastPage", window.location.href.replace(window.location.origin, ""));
    //getChange(this.props, prevProps, this.state, prevState); 
  }

  checkOpenDict(e:MouseEvent)
  {
    if ((e.target as Element).getAttribute("data-dict"))
    {
      e.preventDefault(); 
      this.setState({...this.state, dictionaryOpen: true, dictionaryText:(e.target as Element).getAttribute("data-dict")});
      return false;
    }
  }

  getSelection(e:Event)
  {
    let text = "";
    if (window.getSelection) {
      
      let sel = window.getSelection()?.anchorNode as HTMLElement;
        
        if (sel && iastPattern.test(sel?.parentElement?.className?.toString() || "") || iastPattern.test(sel?.parentElement?.parentElement?.className.toString() || "") || iastPattern.test(sel?.className?.toString() || ""))
        {
          if ( !this.state.dictionaryOpen )
            setTimeout(() => window.scrollTo(window.scrollX, window.scrollY + (sel.parentElement?.getBoundingClientRect().y || 0) - 200), 300);

          text = window.getSelection()?.toString() || "";
          text = text.replace(/[^\p{L}]?(.*?)[^\p{L}].*/msu, "$1");
          if (text.length > 0 && text != this.state.dictionaryText)
          {
            this.setState({...this.state, dictionaryOpen: true, dictionaryText:text});
          }
        }
          
    }
    
  }

  shortcutHandler(e:KeyboardEvent)
  { 
    if (e.key == "s" && e.ctrlKey && e.altKey)
    {
      e.preventDefault();
      e.stopImmediatePropagation();
      
      navigate("/" + this.props.pageContext.locale + "/search");
    }
    else if (e.key == "d" && e.ctrlKey && e.altKey)
    {
      e.preventDefault();
      e.stopImmediatePropagation();
      this.setState({...this.state, dictionaryOpen: !this.state.dictionaryOpen});
      
      if (!this.state.dictionaryOpen)
      {
        //let i = this.dictRef?.current?.querySelector("input");
        //requestAnimationFrame( () => i?.focus());
      }
    }
    else if ((e.key == "+" || e.key == "=")  && e.ctrlKey && e.altKey)
    {
      window.localStorage.setItem("fontSize", (this.state.fontSize + 1).toString());
      this.changeFontSize(this.state.fontSize + 1);
    }
    else if (e.key == "-" && e.ctrlKey && e.altKey)
    {
      this.changeFontSize(Math.max(1, this.state.fontSize - 1));
    }
    else if (e.key == "0" && e.ctrlKey && e.altKey)
    {
      this.changeFontSize(defaultFontSize);
    }
    else if (e.key == "Escape" && this.state.dictionaryOpen)
    {
      e.preventDefault();
      e.stopImmediatePropagation();
      this.setState({dictionaryOpen: false});
    }

  }

  UNSAFE_componentWillUpdate()
  {
    if (this.props.data?.markdownRemark?.fields?.collection != "scriptures")
      clearToC();
  }

  changeFontSize(size:number)
  {
    window.localStorage.setItem("fontSize", size.toString());
    this.setState({...this.state, fontSize: size});
  }

  changeTheme = (nextTheme:number) =>
  {
    window.localStorage.setItem("theme", nextTheme.toString());
    this.setState({...this.state, theme: nextTheme});
    this.setThemeState(nextTheme);
  }

  setThemeState(theme:number)
  {
    if (theme == 2 && (!this.state.sunrise || !this.state.sunset))
    {
      getSunriseAndSunset().then((sunRiseAndSunset) => {
        
        this.setState({...this.state, ...sunRiseAndSunset});
      });
    }
  }

  getThemeIndex()
  {
    let now = Date.now();
    return this.state.theme == 0 || ( this.state.theme == 2 && (this.state.sunset == null || this.state.sunset > now + 7200000) && (!this.state.sunrise || this.state.sunrise < now - 7200000) ) ? 0 : 1;
  }

  addCopyListener()
  {
    window.addEventListener("load", () => {
      let initElement = document.createElement("div");
      let initElementChild  = document.createElement("div");
      initElementChild.className = "iast iast-red dev sharada trans-iast";
      initElement.appendChild(initElementChild);
      toBlob(document.body.appendChild(initElement))
      .then(_ => {document.body.removeChild(initElement) })
    });
    
    document.body.addEventListener("click", e =>
    {
      if (!e.ctrlKey)
        return;

      this.copyToClipboard(e.target as HTMLElement)
    });

    let self = this;

    let startTapLocation:TouchEvent|null = null;

    document.body.addEventListener('touchstart', (e) => {
      startTapLocation = e;
    });

    document.body.addEventListener('touchend', (() => {
      let lastTap = 0;
      let timeout:NodeJS.Timeout | undefined;
      return function detectDoubleTap(event:TouchEvent) {
        const curTime = new Date().getTime();
        const tapLen = curTime - lastTap;
        //const startTouch = startTapLocation!.touches[0];
        //const endTouch = event.touches[0];  
        if (tapLen < 250/* && tapLen > 0 && Math.abs(startTouch.pageX - endTouch.pageX) < 200 && Math.abs(startTouch.pageY - endTouch.pageY) < 200*/ ) {
          self.copyToClipboard(event.target! as HTMLElement);
          event.preventDefault();
        } else {
          timeout = setTimeout(() => {
            clearTimeout(timeout!);
          }, 250);
        }
        lastTap = curTime;
      };
    })());
  }

  copyToClipboard(e:HTMLElement)
  {
    let currNode:HTMLElement = e;
      let scriptureNode:HTMLElement|null = null;
      for (let i = 0; i < 4; i++)
      {
        if (scriptureNodePattern.test(currNode.classList.toString()))
        {
          scriptureNode = currNode;
          break;
        }

        if (!currNode.parentElement)
          break;
        else 
          currNode = currNode.parentElement;
      }

      if ( scriptureNode )
      {
        let container = document.createElement("div");
        container.style.backgroundColor = window.getComputedStyle(document.body, null).getPropertyValue('background-color');
        container.style.padding = "10px";
        container.style.fontSize = "1rem";
        container.style.width = "900px";
        
        let s:HTMLElement = scriptureNode;
        do
        {
          container.appendChild(s.cloneNode(true));
          s = s.nextElementSibling! as HTMLElement;
        }
        while(s && (s.previousElementSibling?.classList.contains("sharada") || (!s.classList.contains("sharada") && !s.classList.contains("chumma"))) && !/H[1-9]/.test(s.tagName) )

        document.body.appendChild(container);
        
        toBlob(container, {quality: 1, pixelRatio: 3})
        .then( blob=> { document.body.removeChild(container); return copyBlobToClipboard(blob!)})
        .then(() => new Toast(this.props.t("copied-to-clipboard"), {position: 'bottom', theme: this.state.theme == 0 ? "light" : "dark", timeout: 1500, style: {message: [["font-size", "0.75rem"]]}}) );
      }
  }


  onClose = () =>
  {
    this.setState({...this.state, sidebarOpen:false});
  }

  login( username:string, password:string )
  {
    login(username, password)
    .then(user => this.setState({...this.state, user: {loading:false, user:user}}))
    .catch(e => this.setState({...this.state, user:{loading:false, user:null}}));
  }

  logout() 
  {
    logout();
    this.setState({...this.state, user:{loading:false, user:null}});
  }

  render()
  {
    
    let translationList = this.props.data?.markdownRemark?.fields?.collection == "scriptures" && this.props.data?.translations?.nodes ? this.props.data.translations.nodes.map((n) => {return {name:n.fields.locale, encrypted: n.fields.encrypted, title:n.fields.locale}}) : [];
    
    return (
       <ThemeProvider theme={themeList[this.getThemeIndex()]}>
            <GlobalStyles />
            <Header>
              <HeaderBg $start={typeof window !== "undefined"}></HeaderBg>
              {
                this.state.anniversary != null && (<Anniversary anniversary={this.state.anniversary || 0} />)
              }
              <Logo />
              <LangSwitcher />
            </Header>
          <UserContext.Provider value={{user: this.state.user, login:this.login, logout:this.logout}}>
            <Navigation
              hidden={this.props.path == "/"} 
              title={this.props.data?.markdownRemark?.frontmatter?.title} 
              locale={this.props.pageContext.locale}
              scriptureLocale={this.props.data?.markdownRemark?.fields.locale}
              collection={this.props.data?.markdownRemark?.fields.collection!}
              slug={this.props.data?.markdownRemark?.fields.slug}
              navContent={this.props.navContent}
              translationList={translationList}  />
            
            <Container fontSize={this.state.fontSize}>
              <ContentRow>
                {this.props.children}
              </ContentRow>
              <Sidebar opened={this.state.sidebarOpen} 
                onClose={() => this.onClose()}
                onDecreaseFontSize={() => this.changeFontSize(Math.max(1,this.state.fontSize - 1))} 
                onIncreaseFontSize={() => this.changeFontSize(this.state.fontSize+1)} 
                isDictionaryOpen={this.state.dictionaryOpen} 
                onOpenDictionary={(isOpen:boolean) => this.setState({...this.state, sidebarOpen: isMobile() ? false : this.state.sidebarOpen, dictionaryOpen:isOpen})} 
                theme={this.state.theme} 
                onChangeTheme={(index) => this.changeTheme(index)} />
              <SidebarToggle onClick={() => this.setState({...this.state, sidebarOpen: true})} />
              
              <DictionaryResizeableWindow ref={this.dictRef} isOpen={this.state.dictionaryOpen && !this.props.uri.includes("/dictionary")}>
                <CloseButton onClick={() => this.setState({...this.state, dictionaryOpen:false})} className="icon-cancel" />
                <DictSearch>
                  {!this.props.uri.includes("/dictionary") && <DictionarySearch inputId="floatingDictionary" uri={this.props.uri} onNavigate={()=>{}} searchText={this.state.dictionaryText} isSticky={false} onChange={(v) => {this.setState({...this.state, dictionaryText:v})}} />}
                </DictSearch>
              </DictionaryResizeableWindow>
            </Container>
          </UserContext.Provider>
            <Footer />
          </ThemeProvider>
        )
  }
};

const Navigation = (props:{title?:string, collection:string, slug:string, locale:string, scriptureLocale?:string, navContent:ReactNode, translationList:{name:string, title:string, encrypted?:boolean}[], hidden?:boolean}) =>
{
  const user = React.useContext(UserContext);
  let pageQuery = useStaticQuery(graphql`
  query NavigationQuery {
    pages: allMarkdownRemark(
      sort: {frontmatter: {title: DESC}}
      filter: {frontmatter: {draft: {nin: true}}, fields: {collection: {in: ["pages"]}}}
      ) {
        nodes {
          frontmatter {
            title
          }
          fields {
            slug
            locale
          }
        }
      }
  }`);

  let translationList = props.translationList.filter((t) => !t.encrypted || user.user.user);
  translationList.sort((a,b) => a.title > b.title ? 1 : -1);

  let [swipePos, setSwipePos] = useState<number>(0);
  let swipeStart = 0;

  const onSwipeStart = (event:TouchEvent) =>
  {
    swipeStart = event.touches[0].screenY;
    //event.preventDefault();
  }

  const onSwipeMove = (event:TouchEvent) =>
  {
    setSwipePos(Math.max(0,event.touches[0].screenY - swipeStart));
    event?.preventDefault();
    return false;
  }

  const onSwipeEnd = (event:TouchEvent) => 
  {
    if ( Math.max(0,event.changedTouches[0].screenY - swipeStart) > 100 )
    {
      window.location.reload();
    }
    swipeStart = 0;
    setSwipePos(0);
  }

  return <Nav pages={pageQuery.pages.nodes.filter((n) => n.fields.locale == props.locale)} 
    title={isMobile() ? props.title : undefined}
    hidden={props.hidden ? true : false}
    onTouchStart={onSwipeStart.bind(this)}
    onTouchMove={onSwipeMove.bind(this)}
    onTouchEnd={onSwipeEnd.bind(this)}
    swipePos={swipePos}>
    {props.navContent}
    {props.translationList.length > 1 && <TranslationList name="translationList" hideOnMobile={false} categoryList={props.translationList} defaultState={props.scriptureLocale} onChange={(v) => navigate("/" + props.locale + "/" + props.collection + "/" + props.slug + (props.locale != v ? "/" + v : ""))} />}
  </Nav>
}

export default withTranslation()(Layout);
