import styled from "styled-components";
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { Button } from "../button/button";
import {login} from "../../utils/auth";

const StyledLogin = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${(props) => props.theme.colors.modalBackground};
`;

const LoginContainer = styled.div`
  padding: 30px;
  max-width: 350px;
  position: relative;
  width: 100% !important;
  background-color: ${(props) => props.theme.colors.bright};
  margin: auto;
  margin-top: 15%;
  border-radius: 2px;
  box-shadow: 0px 2px 2px rgb(0 0 0 / 30%);
  overflow: hidden;
  input, button {
    width: 100%;
    margin-bottom: 10px;
  }
`;

const CloseButton = styled.a`
  position: absolute;
  right: 20px;
  top: 13px;
  display: block;
  font-weight: bold;
  cursor: pointer;
`;

const Title = styled.h1`
  padding-right: 0; 
`;


export const Login = ( props:{onClose:() => void} ) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [failedLogin, setFailedLogin] = useState(false);

  function doLogin(email:string, password:string) {
    setFailedLogin(false);
    login(email,password)
    .then(() =>
    {
      window.location.reload();
    })
    .catch( (e) =>
    {
      setFailedLogin(true);
    });
  }


  return (<StyledLogin>
      <LoginContainer>
      <CloseButton onClick={props.onClose} className="icon-cancel" />
        <Title>{t("login")}</Title><br/>
            <input type="text" name="email" onChange={e => setEmail(e.target.value)} placeholder={t("email")}></input>
            <input type="password" name="password" onChange={e => setPassword(e.target.value)} placeholder={t("password")}></input>
            {failedLogin && t("failed-login")}
            <Button onClick={() => doLogin(email, password)}>{t("login")}</Button>
      </LoginContainer>
  </StyledLogin>);
}