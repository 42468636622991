import { LocalizedLink as Link, useLocalization } from "gatsby-theme-i18n"
import React, {useState} from "react";
import styled from "styled-components";
import { useLocation } from '@reach/router';


const StyledLangSwitcher = styled.div`
  
`;

const DropdownToggle = styled.a`
  position: absolute;
  z-index: 100;
  right: 15px;
  top: 15px;
  font-family: Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  border-radius: 50%;
  border: 1px solid black;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  font-size: 12px;
  cursor: pointer;
  
  @media print {
    display: none;
  }
`;

export const LangSwitcher = () => {
  const { locale, config } = useLocalization();
  const [switched, setSwitched] = useState<boolean>(false);
  const loc = useLocation();
  let pathMatch = loc.pathname.match(/^\/[a-z]{2}(\/.*)/);
  let path:string = pathMatch ? pathMatch[1] : loc.pathname;

  const setLang = (lang:string) => {
    localStorage.setItem("lang", lang);
    setSwitched(false);
  };
  
  let nextLocaleIndex = config.findIndex((e:{code:string}) => e.code == locale);
  let nextLocale = config[nextLocaleIndex + 1] ? config[nextLocaleIndex + 1].code : config[0].code;
  
  return (
    <StyledLangSwitcher>
    <DropdownToggle onClick={(e) => { setSwitched(!switched); e.preventDefault() }} href={"/" + nextLocale}>{locale}</DropdownToggle>
    <DropdownList $switched={switched}>
      {config.filter((e:{code:string}) => e.code != locale)
        .map((e:{code:string}) => ( <DropdownItem to={path} key={e.code} language={e.code} onClick={() => setLang(e.code)}>{e.code}</DropdownItem> ))}
    </DropdownList>
    </StyledLangSwitcher>
  );
};

const DropdownItem = styled(Link)`
  display: block;
  padding: 5px;
  text-transform: uppercase;
`; 

const DropdownList = styled.div<{$switched:boolean}>`
  position: absolute;
  z-index: 100;
  right: 13.5px;
  top: 45px;
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  transition: height 0.3s;
  overflow: hidden;
  text-transform: uppercase;
  width: 30px;
  text-align: center;
  height: ${props => props.$switched ? "auto" : 0}
`;
