/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

//https://dev.to/nnaoi/gatsby-theme-i18n-react-i18next-automatically-change-display-language-by-browser-language-when-initial-rendering-2al4

import CustomLayout from "./wrapPageElement"

var loadingAnim;

export const onClientEntry = (a, pluginOptions = {}) => {
    loadingAnim = document.createElement("div");
    loadingAnim.style.cssText = `
    width: 100%;
    height: 2px;
    background: #AD0000;
    position: fixed;
    bottom: 0px;
    z-index: 1000;
    -webkit-animation: line_animation 2s infinite;
    animation: line_animation 2s infinite;`;

    var animKeyframes = document.createElement("style");
    animKeyframes.textContent = `@keyframes line_animation {
        0%   {left: 0px; width: 0px;}
        50%   {left: 0px; width: 100%;}
        100%   {left: 100%; width: 100%;}
    }`;

    document.body.appendChild(animKeyframes);

    document.body.appendChild(loadingAnim);
};

export const onRouteUpdateDelayed = () => {
    document.body.appendChild(loadingAnim);
}
  
export const onRouteUpdate = () => {
    loadingAnim.remove();
}

const isScripture = /\/(scriptures|dictionary)\/.+/;

export const shouldUpdateScroll = (data) => {
    return !data.pathname.match(isScripture);
}

export const registerServiceWorker = () => false;

export const wrapPageElement = CustomLayout;