import { SandhiRule } from "./sandhi";

export const internalSandhiRules:SandhiRule[] = 
[
        {
        name: "2nd consonant sandhi 'hard to soft consonant'",
        parse: (a, b) => 
        {
            if (!/^(?:g|gh|j|jh|ḍ|ḍh|d|dh|b|bh)/.test(b[0]))
                return [{a:a, b:b}];

            const map = {
                "k": "g",
                "kh": "gh",
                "c": "j",
                "ch": "jh",
                "ṭ": "ḍ",
                "ṭh": "ḍh",
                "t": "d",
                "th": "dh",
                "p": "b",
                "ph": "bh"
            };

            for (const [key, value] of Object.entries(map))
            {
                let r1 = new RegExp(key + "$");
                let match = a.substring(a.length -2).match(r1);
                if ( match )
                    return [{a: a.replace(r1, value), b:b}];
            }
            
            return [{a:a, b:b}];
        },
        reverse: (word) => { 
            if (!/(?:g|gh|j|jh|ḍ|ḍh|d|dh|b|bh)/.test(word))
                return [word];

            const map = {
                "g(g|gh|j|jh|ḍ|ḍh|d|dh|b|bh)": "k",
                "gh(g|gh|j|jh|ḍ|ḍh|d|dh|b|bh)": "kh",
                "j(g|gh|j|jh|ḍ|ḍh|d|dh|b|bh)": "c",
                "jh(g|gh|j|jh|ḍ|ḍh|d|dh|b|bh)": "ch",
                "ḍ(g|gh|j|jh|ḍ|ḍh|d|dh|b|bh)": "ṭ",
                "ḍh(g|gh|j|jh|ḍ|ḍh|d|dh|b|bh)": "ṭh",
                "d(g|gh|j|jh|ḍ|ḍh|d|dh|b|bh)": "t",
                "dh(g|gh|j|jh|ḍ|ḍh|d|dh|b|bh)": "th",
                "b(g|gh|j|jh|ḍ|ḍh|d|dh|b|bh)": "p",
                "bh(g|gh|j|jh|ḍ|ḍh|d|dh|b|bh)": "ph"
            };
            
            for (const [key, value] of Object.entries(map))
            {
                let r1 = new RegExp(key);
                let match = word.match(r1);
                if ( match )
                    return [word.replace(r1, value + match[1])];
            }
            return [word];
        }
    },
    {
        name: "3rd consonant sandhi 'consonants to k,ṭ,p,ṅ'",
        parse: (a, b) =>
        {
            const map = {
                "kh|gh?|ch?|jh":   "k", // ?????? kh just added because of the brazil brother's page mentions
                "th|dh?":   "t", // ?????? th just added because of the brazil brother's page mentions
                "j|ś":    ["k","ṭ"], // we still don't know when to choose which one

                "ḍh?":  "ṭ",
                "ṣ|(?<![kcṭtpgjḍdb])h": ["ṭ", "k"],  // we still don't know when to choose which one
                "bh?":  "p",
                "ñ":    "ṅ",
            };

            for (const [k, vMulti] of Object.entries(map))
            {
                
                let r = new RegExp("("+k+")$");
                
                if ( r.test(a+b) )
                {
                    return typeof vMulti == "string" ? [{a:(a+b).replace(r, vMulti), b:""}] : vMulti.map(v => ({a:(a+b).replace(r, v), b:""})); // HACKY
                }
            }

            return [{a:a, b:b}];
        },
        reverse: (word) =>
        {
            const map:{[key:string]: string[]} = {
                "k":    ["g", "gh", "c", "ch", "j", "jh", "ś", "ṣ", "h", "kh"],
                "ṭ":    ["ḍ", "ḍh", "j", "ś", "h", "ṣ"],
                "t":    ["d", "dh", "th"],
                "p":    ["b", "bh"],
                "ṅ":    ["ñ"]
            };

            let lastChar = word.substring(word.length -1);
            let trimmedWord = word.substring(0, word.length-1);
            
            if ( map[lastChar] )
                return map[lastChar].map(e => trimmedWord + e);
            else
                return [word];
        }
    },
    {   name: "Müller 158 (67) 'c=k'",
        parse: (a, b) =>{
            return [{a:b == "" || /^(a|ā|i|ī|u|ū|ṛ|ṝ|ḷ|ḹ|e|ai|o|au)/.test(b) ? a : a.replace(/c(?!h)$/, "k"), b:b}]; // ALSO possible change to g!!!
        },
        reverse: (word) => {
            const pattern = /(?:k(?!a|ā|i|ī|u|ū|ṛ|ṝ|ḷ|ḹ|e|ai|o|au))|(?:g(?=bh))/g;
            
            if (pattern.test(word))
            {
                pattern.lastIndex = 0;
                return [...word.matchAll(pattern)].map(e => { let r = word.split(""); r[e.index] = "c"; return r.join(""); });
            }
            return [word]
        }
    },
    {   name: "Müller 160 (68) 'ch$=ś > ś$=ṭ'",
        parse: (a, b) =>{
            let newA = a.replace(/ch$/, "ś");
            return [{a:b == "" || !/^(a|ā|i|ī|u|ū|ṛ|ṝ|ḷ|ḹ|e|ai|o|au)/.test(b) ? newA.replace(/ś$/, "ṭ") : newA, b:b}];
        },
        reverse: (word) => {

            const pattern = /(?:ś(?=a|ā|i|ī|u|ū|ṛ|ṝ|ḷ|ḹ|e|ai|o|au))|(?:ṭ(?!a|ā|i|ī|u|ū|ṛ|ṝ|ḷ|ḹ|e|ai|o|au))/g;
            if (pattern.test(word))
            {
                pattern.lastIndex = 0;
                [...word.matchAll(pattern)].map(e => { let r = word.split(""); r[e.index] = "ch"; return r.join(""); });
            }
            return  [word]; // TODO
        }
    },
    {   name: "Müller 161,162 (68) 'j=k|ṭ'",
        parse: (a, b) =>
        {
            return /^(a|ā|i|ī|u|ū|ṛ|ṝ|ḷ|ḹ|e|ai|o|au)/.test(b) ? [{a:a, b:b}] : [{a:a.replace(/(?:j)$/, "k"), b:b}, {a:a.replace(/(?:j)$/, "ṭ"), b:b}];
        },
        reverse: (word) =>
        {
            const pattern = /(?:k|ṭ)(?!a|ā|i|ī|u|ū|ṛ|ṝ|ḷ|ḹ|e|ai|o|au)/g;
            if (pattern.test(word))
            {
                pattern.lastIndex = 0;
                return [...word.matchAll(pattern)].map(e => { let r = word.split(""); r[e.index] = "j"; return r.join(""); });
            };
            return [word];
        }
    },
    {   name: "Müller 76 (29) 'ṭ(t)s'",
        parse: (a, b) =>
        {
            return a[a.length-1] == "ṭ" && b[0] == "s" ? [{a:a, b:b}, {a:a + "t", b:b}] : [{a:a, b:b}];
        },
        reverse: (word) =>
        {
            const pattern = /ṭts/g;
            if (pattern.test(word))
            {
                pattern.lastIndex = 0;
                return [...word.matchAll(pattern)].map(e => { return word.substring(0, e.index) + "ṭs" + word.substring(e.index! + e[0].length); });
            };
            return [word];
        }
    },
    {   name: "Müller 164 (70) 'i=ī|u=ū'",
        parse: (a, b, _1, _2, _3, _4, _5, fullWord) =>
        {
            const map = {"i": "ī", "u": "ū"};
            return [{a: /(i|u)r$/.test(fullWord) && /^(?!a|ā|i|ī|u|ū|ṛ|ṝ|ḷ|ḹ|e|ai|o|au)/.test(b) ? a.replace(/(i|u)(?=.$)/, (_, g1:"i"|"u") => map[g1] ) : a, b:b}]; // ALSO possible change to g!!!
        },
        reverse: (word) =>
        {
            const map = {"ī": "i", "ū": "u"};
            const pattern = /(ī|ū)(?=r|ḥ)/g;
            if (pattern.test(word))
            {
                pattern.lastIndex = 0;
                return [...word.matchAll(pattern)].map(e => { let r = word.split(""); r[e.index] = map[(e[1] as "ī"|"ū")]; return r.join(""); });
            };
            return [word];
        }
    },
    {   name: "13th consonant sandhi 'fallback to base of class'", // OPTIONAL Doubling/dropping roules are missing
        parse: (a, b) =>
        {
            if (!/^(?:gh?|jh?|ḍh?|dh?|bh?)/.test(b))
                return [{a:a, b:b}];

            const map = {
                "kh?|gh":   "g", 
                "ch?|jh":   "j",
                "ṭh?|ḍh":   "ḍ",
                "th?|dh":   "d",
                "ph?|bh":   "b"
            };

            for (const [k, v] of Object.entries(map))
            {
                let r = new RegExp("(" +k+")$");
                
                if ( r.test(a) )
                {
                    a = a.replace(r, v);
                    return [{a:a, b:b}];
                }
            }

            return [{a:a, b:b}];
        },
        reverse: (word) =>
        {
            const map = {
                "g": ["k", "kh", "gh"], 
                "j": ["c", "ch", "jh"],
                "ḍ": ["ṭ", "ṭh", "ḍh"],
                "d": ["t", "th", "dh"],
                "b": ["p", "ph", "bh"] 
            };

            for (const [k, v] of Object.entries(map))
            {
                let r = new RegExp("(" +k +")(?=gh?|jh?|ḍh?|dh?|bh?)");
                
                if ( r.test(word) )
                {
                    return v.map(rep => word.replace(r, rep));
                }
            }

            return [word];
        }
    },
    {   name: "16th consonant sandhi 's=ṣ'",
        parse: (a, b) => 
        {
            if ( b[0] == "s" && new RegExp("(?:i|ī|ṛ|ṝ|ḷ|ḹ|u|ū|e|ai|o|au|ṃ|ṁ|ḥ|y|r|l|v|k|kh|g|gh|ṅ|(?:(?<![kcṭtpgjḍdb])h))$").test(a.substring(a.length-2)) && !/^(?:ṛ|ṝ|r|g|gh|ṅ|j|jh|ñ|ḍ|ḍh|ṇ|d|dh|n|b|bh|ma|l|v|ś|ṣ|s)/.test(b.substring(1)))
                return [{a: a, b:b.replace("s", "ṣ")}];
            else
                return [{a:a, b:b}];
        },
        reverse: (word) => { 
            return [word.replace(new RegExp("(i|ī|ṛ|ṝ|ḷ|ḹ|u|ū|e|ai|o|au|ṃ|ṁ|ḥ|y|r|l|v|k|kh|g|gh|ṅ|(?:(?<![kcṭtpgjḍdb])h))ṣ(?!ṛ|ṝ|r|g|gh|ṅ|j|jh|ñ|ḍ|ḍh|ṇ|d|dh|n|b|bh|ma|l|v|ś|ṣ|s)"), "$1s")];
        }
    },
    
    {
        name: "18th consonant sandhi 'n=ṇ'",
        parse: (a, b) => 
        {
            if ( new RegExp("[rṣṛṝ](?:a|ā|i|ī|ṛ|ṝ|ḷ|ḹ|u|ū|(?<![kcṭtpgjḍdb])h|y|r|v|ṅ|ñ|ṇ|n|k|kh|g|gh|ṅ|p|ph|b|bh|m|ṁ|ṃ|h|e|ai|o|au)*n(?=[^$])").test(a.substring(a.length-3) + b.substring(0,3)))
                return [{a: a, b:b.replace("n", "ṇ")}];
            else
                return [{a:a, b:b}];
        },
        reverse: (word) => { 
            return [word.replace(new RegExp("([rṣṛṝ](?:a|ā|i|ī|ṛ|ṝ|ḷ|ḹ|u|ū|(?<![kcṭtpgjḍdb])h|y|r|v|ṅ|ñ|ṇ|n|k|kh|g|gh|ṅ|p|ph|b|bh|m|ṁ|ṃ|h|e|ai|o|au)*)ṇ(.*)"), "$1n$2")];
        }
    },
    {
        name: "20th consonant sandhi 'ṅ+(k)|ṇ+(ṭ)'",
        parse: (a, b) => 
        {
            let m = a.substring(a.length -1).match(/(ṅ|ṇ)$/);
            if ( m && /^ś|ṣ|s/.test(b[0]))
                return [{a:a, b:b}, {a: a, b: m[0] == "ṅ" ? "k" : "ṭ" + b}] // OPTIONAL return {a: a, b: m[0] == "ṅ" ? "(k)" : "(ṭ)" + b}
            else
                return [{a:a, b:b}];
        },
        reverse: (word) => { 
            return [word.replace(/((?:ṅ(?=k))|(?:ṇ(?=ṭ)))(?:k|ṭ)(ś|ṣ|s)/, "$1$2")];
        }
    },
    {
        name: "1st visarga sandhi '(s|r)$ = ḥ'",
        parse: (a, b, _1, _2, _3, origA) => 
        {   
            return [{a:a.replace(/(s|r)$/, (res) => res == "s" ? "ḥ" : /^(?:kh?|ch?|ṭh?|th?|ph?|$)/.test(b) ? "ḥ" : res ), b:b}];
        },
        reverse: (word) => { 
            const pattern = /ḥ(.?)/g;

            if (pattern.test(word))
            {
                pattern.lastIndex = 0;
                let matches = word.matchAll(pattern);
                let results = [];
                const rPattern = /$|[kcṭtp]/;
                for (const match of matches)
                {
                    let r1 = word.split("");
                    r1[match.index] = "s";
                    results.push(r1.join(""));

                    if ( rPattern.test(match[1]) )
                    {
                        let r2 = word.split("");
                        r2[match.index] = "r";
                        results.push(r2.join(""));
                    }
                }
                return results;
            }
            return [word];
        }
    },
    {
        name: "Declenson 'aspirant throwback' rule http://sanskrit.segal.net.br/en/grammar?doc=declension_conso#conso_aspth",
        parse: (a, b, _1, _2, _3, _4, origB, fullWord ) =>
        {
            //console.log(a,b,origB, fullWord)
            const origPattern = new RegExp("(g|ḍ|d|b)(a|ā|i|ī|u|ū|ṛ|ṝ|ḷ|ḹ|e|ai|o|au)(gh|ḍh|dh|bh|(?<![kcṭtpj])h)");
            let match = fullWord.match(origPattern);
            
            if (match && a.substring(a.length - 1) != "h" && b[0] != "h") 
                return [{a:a.replace(/(g|ḍ|d|b)(a|ā|i|ī|u|ū|ṛ|ṝ|ḷ|ḹ|e|ai|o|au)(?=\w?$)/, "$1h$2"), b:b}];
            else if (match && /^(?:bh|s)/.test(origB)) // could not find the rule for this part: /[bs]/.test(origB[0])
                return [{a:a.replace(/(g|ḍ|d|b)(a|ā|i|ī|u|ū|ṛ|ṝ|ḷ|ḹ|e|ai|o|au)dh/, "$1h$2d"), b:b}];
            else
                return [{a:a, b:b}];
        },
        reverse: (word) =>
        {
            const pattern = /(g|ḍ|d|b)h(a|ā|i|ī|u|ū|ṛ|ṝ|ḷ|ḹ|e|ai|o|au)(dbh|[tṭdḍ])/;
            const match = word.match(pattern);
            if (match)
            {
                let result = match[3] == "dbh" ? [word.replace(pattern, "$1$2dhbh")] : ["gh", "ḍh", "dh", "bh", "h", "$3"].map(e => word.replace(pattern, "$1$2" + e));
                
                return result;
            }
            return [word];
        }
    },
    {
        name: "Declension 'ṅñṇmṃ insertion' rule http://sanskrit.segal.net.br/en/grammar?doc=declension_conso",
        parse: (a, b, cas, num, gen ) =>
        {
            
            if (["nom", "acc", "voc"].includes(cas) && num == "p" && gen == "neu" && b == "i")
            {
                
                const patterns = [
                    {pattern: /(k|kh|g|gh)$/, replace: "ṅ$1"},
                    {pattern: /(c|ch|j|jh)$/, replace: "ñ$1"},
                    {pattern: /(ṭ|ṭh|ḍ|ḍh)$/, replace: "ṇ$1"},
                    {pattern: /(t|th|d|dh)$/, replace: "n$1"},
                    {pattern: /(p|ph|b|bh)$/, replace: "m$1"},
                    {pattern: /(ś|ṣ|s|h)$/, replace: "ṃ$1"},
                ];

                for ( let i = 0; i < patterns.length; i++)
                {
                    if (patterns[i].pattern.test(a))
                        return [{a:a.replace(patterns[i].pattern, patterns[i].replace), b:b}];
                }
            }
            return [{a:a, b:b}];
        },
        reverse: (word:string) => { 
            const patterns = [
                /ṅ(k|kh|g|gh)/,
                /ñ(c|ch|j|jh)/,
                /ṇ(ṭ|ṭh|ḍ|ḍh)/,
                /n(t|th|d|dh)/,
                /m(p|ph|b|bh)/,
                /ṃ(ś|ṣ|s|h)/];

            for ( let i = 0; i < patterns.length; i++)
            {
                if (patterns[i].test(word))
                    return [word.replace(patterns[i], "$1")];
            }

            return [word]; // TODO
        }
    },
    {
        name: "Declension rule 'bh|bh=p' http://sanskrit.segal.net.br/en/grammar?doc=declension_conso#conso_labials",
        parse: (a, b, cas, num, gen ) =>
        {
            return [b[0] == "s" ? {a:a.replace(/(bh?|ph)$/, "p"), b:b} : {a:a, b:b}];
        },
        reverse: (word:string) => { 
            const map = ["bh", "b", "ph"];
            const pattern = /ps/;
            return map.map(e => word.replace(pattern, e + "s"));
        }
    },
    {
        name: "Declension rule 'tsu' http://sanskrit.segal.net.br/en/grammar?doc=declension_conso#conso_labials",
        parse: (a, b, cas, num ) =>
        {
            const pattern = /(?:kh?|gh?|ṅ|ch?|jh?|ñ|th?|dh?|n|bh?|m)$/;
            return [cas == "loc" && num == "p" && b == "su" && pattern.test(a) ? {a:a.replace(pattern, "t"), b:b} : {a:a, b:b}];
        },
        reverse: (word:string) => {
            const pattern = /tsu$/; 
            const map = ["k", "kh", "g", "gh", "ṅ", "c", "ch", "j", "jh", "ñ", "ṇ", "t", "th", "d", "dh", "n", "b", "bh", "m"];
            return map.map(e => word.replace(pattern, e + "su"));
        }
    },
    {
        name: "Declension rule 'ṭsu' http://sanskrit.segal.net.br/en/grammar?doc=declension_conso#conso_labials",
        parse: (a, b, cas, num ) =>
        {
            const pattern = /(?:ṭh?|ḍh?)$/;
            return [cas == "loc" && num == "p" && b == "su" && pattern.test(a) ? {a:a.replace(pattern, "ṭ"), b:b} : {a:a, b:b}];
        },
        reverse: (word:string) => {
            const pattern = /ṭsu$/; 
            const map = ["ṭ", "ṭh", "ḍ", "ḍh"];
            return map.map(e => word.replace(pattern, e + "su"));
        }
    }
];

















const externalSandhiRules:SandhiRule[] = 
[
    {   name: "simplified rules",
        parse: (a, b) =>
        {
            const map = [
                ["a", "a|ā", "ā"],
                ["a", "i|ī", "e"],
                ["a", "u|ū", "o"],
                ["a", "ṛ", "ar"],
                ["a", "e|ai", "ai"],
                ["a", "o|au", "au"],

                ["ā", "a|ā", "ā"],
                ["ā", "i|ī", "e"],
                ["ā", "u|ū", "o"],
                ["ā", "ṛ", "ar"],
                ["ā", "e|ai", "ai"],
                ["ā", "o|au", "au"],

                ["i", "a", "ya"], //FOR ALL i: if final vowel is not at the end of a dual form
                ["i", "ā", "yā"],
                ["i", "i|ī", "ī"],
                ["i", "u", "yu"],
                ["i", "ū", "yū"],
                ["i", "ṛ", "yṛ"],
                ["i", "e", "ye"],
                ["i", "ai", "yai"],
                ["i", "o", "yo"],
                ["i", "au", "yau"],

                ["ī", "a", "ya"], //FOR ALL ii: if final vowel is not at the end of a dual form
                ["ī", "ā", "yā"],
                ["ī", "i|ī", "ī"],
                ["ī", "u", "yu"],
                ["ī", "ū", "yū"],
                ["ī", "ṛ", "yṛ"],
                ["ī", "e", "ye"],
                ["ī", "ai", "yai"],
                ["ī", "o", "yo"],
                ["ī", "au", "yau"],

                ["u", "a", "va"], //FOR ALL u: if final vowel is not at the end of a dual form
                ["u", "ā", "vā"],
                ["u", "i", "vi"],
                ["u", "ī", "vī"],
                ["u", "u|ū", "ū"],
                ["u", "ṛ", "vṛ"],
                ["u", "e", "ve"],
                ["u", "ai", "vai"],
                ["u", "o", "vo"],
                ["u", "au", "vau"],

                ["ū", "a", "va"], //FOR ALL uu: if final vowel is not at the end of a dual form
                ["ū", "ā", "vā"],
                ["ū", "i", "vi"],
                ["ū", "ī", "vī"],
                ["ū", "u|ū", "ū"],
                ["ū", "ṛ", "vṛ"],
                ["ū", "e", "ve"],
                ["ū", "ai", "vai"],
                ["ū", "o", "vo"],
                ["ū", "au", "vau"],

                ["ṛ", "a", "ra"],
                ["ṛ", "ā", "rā"],
                ["ṛ", "i", "ri"],
                ["ṛ", "ī", "rī"],
                ["ṛ", "u", "ru"],
                ["ṛ", "ū", "rū"],
                ["ṛ", "ṛ", "ṝ"],
                ["ṛ", "e", "re"],
                ["ṛ", "ai", "rai"],
                ["ṛ", "o", "ro"],
                ["ṛ", "au", "rau"],

                ["e", "a", "e '"], //FOR ALL e: if final vowel is not at the end of a dual form
                ["e", "ā", "a ā"],
                ["e", "i", "a i"],
                ["e", "ī", "a ī"],
                ["e", "u", "a u"],
                ["e", "ū", "a ū"],
                ["e", "ṛ", "a ṛ"],
                ["e", "e", "a e"],
                ["e", "ai", "a ai"],
                ["e", "o", "a o"],
                ["e", "au", "a au"],

                ["ai", "a", "ā a"],
                ["ai", "ā", "ā ā"],
                ["ai", "i", "ā i"],
                ["ai", "ī", "ā ī"],
                ["ai", "u", "ā u"],
                ["ai", "ū", "ā ū"],
                ["ai", "ṛ", "ā ṛ"],
                ["ai", "e", "ā e"],
                ["ai", "ai", "ā ai"],
                ["ai", "o", "ā o"],
                ["ai", "au", "ā au"],

                ["o", "a", "o '"],
                ["o", "ā", "avā/a ā"],
                ["o", "i", "avi"],
                ["o", "ī", "avī"],
                ["o", "u", "avu"],
                ["o", "ū", "avū"],
                ["o", "ṛ", "avṛ"],
                ["o", "e", "ave"],
                ["o", "ai", "avai"],
                ["o", "o", "avo"],
                ["o", "au", "avau"],

                ["au", "a", "āva"],
                ["au", "ā", "āvā"],
                ["au", "i", "āvi"],
                ["au", "ī", "āvī"],
                ["au", "u", "āvu"],
                ["au", "ū", "āvū"],
                ["au", "ṛ", "āvṛ"],
                ["au", "e", "āve"],
                ["au", "ai", "āvai"],
                ["au", "o", "āvo"],
                ["au", "au", "āvau"],
                
                ["k", "a|ā|i|ī|u|ū|ṛ|ṝ|ḷ|ḹ|e|ai|o|au|y|r|l|v|ś|gh?|jh?|ḍh?|dh?|bh?", "g"],
                ["k", "h", "g/gh"],
                ["k", "n|m", "ṅ"],
                
                ["ṭ", "a|ā|i|ī|u|ū|ṛ|ṝ|ḷ|ḹ|e|ai|o|au|y|r|l|v|gh?|jh?|ḍh?|dh?|bh?|n|m", "ḍ"],  //h -> ḍ(ḍh)
                ["ṭ", "h", "ḍ/ḍh"],
                ["ṭ", "n|m", "ṇ"],
                
                ["t", "a|ā|i|ī|u|ū|ṛ|ṝ|ḷ|ḹ|e|ai|o|au|y|r|v|gh?|dh?|bh?", "d"],
                ["t", "l", "l"],
                ["t", "ś", "c/ch"],
                ["t", "h", "d/dh"],
                ["t", "ch?", "c"],
                ["t", "jh?", "j"],
                ["t", "ṭh?", "ṭ"], 
                ["t", "ḍh?", "ḍ"],
                ["t", "n|m", "n"],
                
                ["p", "a|ā|i|ī|u|ū|ṛ|ṝ|ḷ|ḹ|e|ai|o|au|y|r|l|v|gh?|jh?|ḍh?|dh?|bh?", "g"],
                ["p", "h", "b/bh"],
                ["p", "n|m", "m"],

                ["(?:a|i|u|ṛ|ḷ)ṅ", "a|ā|i|ī|u|ū|ṛ|ṝ|ḷ|ḹ|e|ai|o|au", "ṅ"], 

                ["(?<=a|i|u|ṛ|ḷ)n", "a|ā|i|ī|u|ū|ṛ|ṝ|ḷ|ḹ|e|ai|o|au", "nn"], 
                ["n", "l", "ṃl"], 
                ["n", "ś", "ñś/ñch"], // !!!!!
                ["n", "ch?", "ṃś"],
                ["n", "jh?", "ñ"], 
                ["n", "ṭh?", "ṃṣ"], 
                ["n", "ṭh?", "ṃṣ"],
                ["n", "ḍh?", "ṇ"],
                ["n", "th?", "ṃs"],

                ["m", "y|r|l|v|ś|ṣ|s|h|kh?|gh?|ch?|jh?|ṭh?|ḍh?|th?|dh?|ph?|bh?|n|m", "ṃ"],
                
                ["aḥ", "a", "o'"],
                ["aḥ", "ā|i|ī|u|ū|ṛ|ṝ|ḷ|ḹ|e|ai|o|au", "a"],
                ["aḥ", "y|r|l|v|h", "ṃ"],
                ["aḥ", "gh?|jh?|ḍh?|dh?|bh?|n|m", "o"], // if the final visargah r, then it revers to r!!!!!!!!!!
                ["aḥ", "ch?", "aś"],
                ["aḥ", "ṭh?", "aṣ"],
                ["aḥ", "th?", "as"],
            
                ["āḥ", "a|ā|i|ī|u|ū|ṛ|ṝ|ḷ|ḹ|e|ai|o|au|y|r|l|v|h", "ā"],
                ["āḥ", "gh?|jh?|ḍh?|dh?|bh?|n|m", "ā"], // if the final visargah r, then it revers to r!!!!!!!!!!
                ["āḥ", "ch?", "āś"],
                ["āḥ", "ṭh?", "āṣ"],
                ["āḥ", "th?", "ās"],

                ["(?<!a|ā)ḥ", "a|ā|i|ī|u|ū|ṛ|ṝ|ḷ|ḹ|e|ai|o|au|y|l|v|h|gh?|jh?|ḍh?|dh?|bh?|n|m", "r"],
                ["(?<!a|ā)ḥ", "r", ""],
                ["(?<!a|ā)ḥ", "ch?", "ś"],
                ["(?<!a|ā)ḥ", "ṭh?", "ṣ"],
                ["(?<!a|ā)ḥ", "th?", "s"],
            ];

            for (let i = 0; i < map.length; i ++)
            {
                let e = map[i];
                let r1 = new RegExp(e[0] + "$", "i");
                let r2 = new RegExp( "^" + e[1], "i");
                if (r1.test(a) && r2.test(b))
                    return [{a:a.replace(r1, e[2]), b:b.replace(r2, "")}];
            }

            return [{a:a, b:b}];
        },
        reverse: (word:string) =>
        {
            return [word]; // TODO: do it
        }
    }
];
