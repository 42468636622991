import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  /* Box sizing rules */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  /* Remove default margin */
  body,
  h1,
  h2,
  h3,
  h4,
  p,
  figure,
  blockquote,
  dl,
  dd {
    margin: 0;
  }

  /* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
  ul[role="list"],
  ol[role="list"] {
    list-style: none;
  }

  /* Set core root defaults */
  html:focus-within {
    scroll-behavior: smooth;
  }

  /* Set core body defaults */
  body {
    min-height: 100vh;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
  }

  /* A elements that don't have a class get default styles */
  a:not([class]) {
    text-decoration-skip-ink: auto;
  }

  /* Make images easier to work with */
  img,
  picture {
    max-width: 100%;
    display: block;
  }

  /* Inherit fonts for inputs and buttons */
  input,
  button,
  textarea,
  select {
    font: inherit;
  }

  /* Remove all animations and transitions for people that prefer not to see them */
  @media (prefers-reduced-motion: reduce) {
    html:focus-within {
    scroll-behavior: auto;
    }
    *,
    *::before,
    *::after {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }


  body {
    margin: 0;
    padding: 0;
    background: ${(props) => props.theme.colors.background};
    color: ${(props) => props.theme.colors.black};
    font-size: 0.9em;
    overflow-y: scroll;
    -webkit-tap-highlight-color: ${(props) => props.theme.colors.pink + "33"};
  }

  p {
    margin-bottom: 1.6em;
  }

  
  html {
    font-family: "Lora";
    scroll-behavior: smooth;
  }
  
  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.red};
    transition: color 0.3s;
    -webkit-tap-highlight-color: ${(props) => props.theme.colors.pink + "33"};
  }

  a:hover {
    color: #dc0000; 
  }

  h1 {
    font-size: 1.4em;
    padding: 1.1em 0 0.2em;
    padding-right: 5em;
    min-width: 250px;
    margin-bottom: 1em;
    font-weight: normal;
    display: inline-block;
    border-bottom: ${(props) => `2px solid ${props.theme.colors.red}`};
  }

  h2 {
    font-size: 1.25em;
    padding: 2em 0 2em;
    font-weight: normal;
  }

  input, textarea, select, [contenteditable] {
    height: 2.5em;
    padding: 0.9em 0.7em;
    background-color: ${(props) => props.theme.colors.white};
    border: 1px solid ${props => props.theme.colors.gray};
    color: ${props => props.theme.colors.black};
    border-radius: 4px;
    box-shadow: none;
    box-sizing: border-box;
    appearance: none;
    &:focus {
      border: 1px solid ${props => props.theme.colors.red};
      outline: 0;
    }

    @media print { display: none }
  }

  hr {
    margin-top: 2em;
    border: 1px solid ${props => props.theme.colors.lightgray}
  }

  /* custom scrollbar */
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background-color: ${props => props.theme.colors.bright};
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${props => props.theme.colors.gray};
    border-radius: 6px;
    border: 1px solid ${props => props.theme.colors.gray};
    background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: ${props => props.theme.colors.darkgray};
  }

  ::-moz-selection { /* Code for Firefox */
    color: ${props => props.theme.colors.black};
    background: ${props => props.theme.colors.pink};
  }

  ::selection {
    color: ${props => props.theme.colors.black  };
    background: ${props => props.theme.colors.highlight};
  }

 

  .dev, .dev-comment{
    font-family: "Sanskrit2003";
    color: ${(props) => props.theme.colors.red};
    font-size: 1.25em;
  }

  .dev {
    text-align: center;
  }

  .dev-comment, .sharada-comment{
    color: ${(props) => props.theme.colors.green};
    text-align: left;
  }

  .sharada, .sharada-comment, .chumma-sharada{
    font-family: "Sharada";
    font-size: 1.25em;
  }

  .sharada, .chumma-sharada{
    color: ${(props) => props.theme.colors.red};
    text-align: center;
  }

  .iast, .iast-comment {
    font-weight: 500;
  }

  .quote-iast, .quote-trans-iast {
    font-style: italic;
  }

  .iast, .quote-iast {
    text-align: center;
  }

  .iast-comment {
    text-align: left;
  }

  .author {
    text-align: center;
    font-size: 1em;
  }

  .book {
    text-align: center;
    font-size: 1.2em;
    font-weight: bold;
  }

  .book-subtitle {
    text-align: center;
    font-size: 1em;
    font-weight: bold;
  }

  .chumma-iast, .chumma-iast > * {
    text-align: center;
    font-weight: bold;
    color: #770000 !important;
  }
  .chumma-trans {
    text-align: center;
    font-weight: bold;
  }

  .chapter-subtitle {
    text-align: center;
    margin-bottom: 2.5em;
    font-size: 1em;
  }

  .chapter-title-trans {
    text-align: center;
    font-size: 0.9em;
  }

  .chapter-subtitle-trans, .chapter-subtitle-notitle {
    text-align: center;
    font-size: 1em;
    margin-top: -2em;
    margin-bottom: 2.5em;
  }

  .trans-iast-comment .iast-red{
    color: ${(props) => props.theme.colors.green};
  }

  .iast-red{
    font-style: normal;
    font-weight: normal;
    color: ${(props) => props.theme.colors.red};
  }

  .hidden {
    display:none;
  }

  .italic {
    font-style: italic;
  }
  
  
  





  .gcsc-find-more-on-google, .gsc-adBlock {
    display: none !important;
  }

  .gsc-input {
    padding: 5px !important;
    font-family: "Lora";
  }

  .gsc-above-wrapper-area {
    border-bottom: none !important;
    padding: 0 !important;
  }

  .gsc-search-button-v2 {
    margin-top: 0 !important;
  }

  .gsc-input table {
    margin-bottom: 0;
  }

  td.gsc-input {
    padding: 0!important;
  }

  .gsc-input td, .gsc-search-button {
    padding: 0;
    border-bottom: none;
  }

  .gsc-search-button {
    height: 41px;
    margin-bottom: 0;
    cursor: pointer;
    border-radius: 4px;
  }

  background-color: ${(props) => props.theme.colors.white};
  .gsc-option-menu-item-highlighted {
    background-color: none !important;
  }



  .gs-visibleUrl {
    color: ${(props) => props.theme.colors.darkgray} !important;
  }

  #gs_cb50 {
    color: ${(props) => props.theme.colors.lightgray};
  }

  .gsc-control-cse {
    margin: 0 !important;
    padding: 0 !important;
    background: none !important;
    border: none !important;
  }

  .gsc-input-box {
    height: auto !important;
    border-radius: 4px;
    background: none !important;
    border-color: ${(props) => props.theme.colors.red} !important;
    margin-right: 5px;
  }

  #searchContainer {
    min-height: 100px;
  }

  .gs-snippet {
    background-color: ${(props) => props.theme.colors.background} !important;
    border: ${(props) => props.theme.colors.red} !important;
    color: ${(props) => props.theme.colors.black} !important;
  }

  .gsc-result, .gsc-webResult.gsc-result:hover {
    background-color: ${(props) => props.theme.colors.background} !important;
    border: none !important;
  }

  @keyframes hammer {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`;

export { GlobalStyles };
