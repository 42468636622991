import styled from "styled-components";
import React, {ChangeEvent, ReactNode, useState} from "react";

type RadioButtonProps<V> = {
  children:ReactNode;
  className?:string;
  selected?:boolean;
  name:string;
  value:V;
  onSelect:(value:V) => void;
}

export const RadioButton = <V,>({name, value, selected, onSelect, children, className} : RadioButtonProps<V>) => {
  return (
    
    <RadioContainer className={className} >
      <RadioInput type="radio" name={name} checked={selected} id={name+"_"+value} onClick={()=>{onSelect(value)}} readOnly />
      <RadioLabel htmlFor={name+"_"+value} >{children}</RadioLabel>
    </RadioContainer>
  );
};

const RadioContainer = styled.div`
  & > input[type="radio"]:checked + label {
    background-color: ${(props) => props.theme.colors.pink};
    border-color: ${props => props.theme.colors.gray};
  }
`;

const RadioInput = styled.input`
  opacity: 0;
  position: fixed;
  width: 0;
  height: 0;
  padding: 0;
  border: none; 
  margin: 0;
`;

const RadioLabel = styled.label`
  display: inline-block;
  padding: 0 1em;
  color: ${props => props.theme.colors.black};
  background-color: ${props => props.theme.colors.bright};
  text-align: center;
  font-size: 0.625em;
  font-weight: 600;
  line-height: 3em;
  letter-spacing: .1em;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.colors.gray};
  cursor: pointer;
  box-sizing: border-box;
  width: 100%;
  
  &:hover {
    background-color:${props => props.theme.colors.white};
  }
`;