import styled from "styled-components";
import React, { Ref, useEffect, useRef, useState } from "react";
import { Button, MultiToggleButton } from "../button";
import { changeSign, getSignState, toggleHide, getState } from "./scripture-text-manager";
import { ToggleButton } from "../button/toggle-button";
import {media} from "../../styles/theme";
import { useTranslation } from "react-i18next";
import { useLocalization } from "gatsby-theme-i18n";
import { RecentlyRead } from "../../types/posts";

const StyledSidebar = styled.div<{$opened:boolean}>`
  position: fixed;
  font-size: 0.85rem;
  ${media.mobile`
    width: 100%;
    right: ${props => props.$opened ? 0 : "-100%"};
  `}
  ${media.tabletMin`
    width: 300px;
    right: ${props => props.$opened ? 0 : "-300px"}; //-300px
  `}
  z-index: 1000;
  top: 0;
  bottom: 0;
  background-color: ${props => props.theme.colors.bright};
  border-left: 2px solid ${props => props.theme.colors.gray};
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  transition: all .3s;
  @media print { display: none; }
`;

const SidebarToggleContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;
  gap: 5px;
`;

const SidebarHorizontalContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 3px;


  > * {
    flex: 1;
    padding: 5px;
    height: 28px;
  }
`;

const StyledSidebarToggle = styled.a`
  position: fixed;
  bottom: 12px;
  right: 5px;
  z-index: 999;
  font-size: 1.6rem;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background-color: ${(props) => props.theme.colors.bright + "d6"};
  padding: 1px;
  cursor: pointer;
`;

const CloseButton = styled.a`
  position: absolute;
  padding: 10px;
  margin: 10px;
  right: 20px;
  top: 13px;
  display: block;
  font-weight: bold;
  cursor: pointer;
`;

const ToCContent = styled.div`
  height: 100%;
  padding-left: 0px;
  overflow-y: scroll;
  flex: 1;
  text-align: left;
  & p {
    margin-bottom: 0.5em;
  }

  & .MsoToc1 {
    margin-left: 0em;
  }

  & .MsoToc2 {
    margin-left: 2em;
  }

  & .MsoToc3 {
    margin-left: 4em;
  }

  & .MsoToc4 {
    margin-left: 6em;
  }
`;

const ToCTitle = styled.div`
  font-weight: bold;
  text-align: center;
  font-size: 1.6em;
  margin-bottom: 3em;
`;

const BookmarkTitle = styled.div`
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  width: 100%;
  flex: 1;
  font-size: 0.8em;
  margin-top: 15px;
  margin-bottom: 5px;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray};
`;

const Bookmark = styled.a`
  margin-bottom: 5px;
`;

const RedToggleButton = styled.span`
  color: ${(props) => props.theme.colors.red};
`;

const SidebarToggleClickArea = styled.div`
  position: fixed;
  right: 0;
  height: 80px;
  bottom: 0;
  width: 80px;
  z-index: 99;
  @media print { display: none; }
`;

type SidebarProps =
{
  opened:boolean;
  onChangeTheme:(i:number) => void;
  onClose: () => void;
  onOpenDictionary: (isOpen:boolean) => void;
  theme:number;
  isDictionaryOpen: boolean;
  onDecreaseFontSize: () => void;
  onIncreaseFontSize: () => void;
}

type SidebarToggleProps = 
{
  onClick: () => void;
}

export const SidebarToggle: React.FC<SidebarToggleProps> = (props:SidebarToggleProps) => {  
  let inTimer:NodeJS.Timeout|null = null;
  let outTimer:NodeJS.Timeout|null = null;


  useEffect(() =>
  {
    document.addEventListener("mouseover", handleDocumentOver);
    document.addEventListener("mouseleave", handleDocumentOut);

    return () => {
      document.removeEventListener("mouseover", handleDocumentOver);
      document.removeEventListener("mouseleave", handleDocumentOut);
      window.removeEventListener("mouseout", handleOut);
    };
  }, []);

  
  const handleDocumentOver = (e:MouseEvent) => { if (e.target == document.children[0] && outTimer) { clearTimeout(outTimer); } };
  const handleDocumentOut = (event:MouseEvent) => {if (event.clientY <= 0 || event.clientX <= 0 || (event.clientX >= window.innerWidth || event.clientY >= window.innerHeight)) {handleOut(); }};
  const handleOut = () => {  outTimer = setTimeout( () => inTimer && clearTimeout(inTimer), 500 ); };
  const handleOver = () => { if (inTimer) clearTimeout(inTimer); inTimer = setTimeout(() => {props.onClick()}, 100); };
  
  return (
    <SidebarToggleClickArea onMouseOver={handleOver}>
      <StyledSidebarToggle className="icon-compass" onClick={() => props.onClick()} ></StyledSidebarToggle>
    </SidebarToggleClickArea>
  );
}

let opened = false; // VERY DIRTY HACK, but I have no idea how the opened prop can change back to false without any notice.

export const Sidebar = (props:SidebarProps) => {  
  const { t } = useTranslation();
  opened = props.opened;

  const readingStates:Array<{key:string, value:string}> = [
    {
      key: "cont",
      value: t("cont-reading")
    },
    {
      key: "by-shloka",
      value: t("by-shloka")
    }
  ];

  const node = useRef<HTMLDivElement>();
  const [restorePosition, setRestorePosition] = useState(typeof window == "undefined" || JSON.parse(window.localStorage.getItem("restorePosition") || "true") );
  let savedReadingMode = typeof window == "undefined" ? 0 : readingStates.findIndex(e=> window.localStorage.getItem("readingMode") == e.key) || 0;
  const [readingMode, setReadingMode] = useState(savedReadingMode == -1 ? 0 : savedReadingMode);
  const { locale } = useLocalization();
  const [bookmarksOpen, setBookmarksOpen] = useState(typeof window == "undefined" ? true : JSON.parse(window.localStorage.getItem("bookmarksOpen") || "true"));

  const themeButtonStates:Array<{key:string, value:string}> = [
    {
      key: "bright",
      value: t("bright-theme")
    },
    {
      key: "dark",
      value: t("dark-theme")
    },
    {
      key: "auto",
      value: t("auto-theme")
    }
  ];

  const multiButtonStates:Array<{key:string, value:string}> = [
    {
      key: "sharada",
      value: "Śāradā"
    },
    {
      key: "dev",
      value: "Devanāgarī"
    },
    {
      key: "shardev",
      value: "Śār.&Dev."
    },
    {
      key: "off",
      value: t("no-sign")
    }
  ];

  const changeRestorePosition = (state:boolean) =>
  {
    window.localStorage.setItem("restorePosition", JSON.stringify(state));
    setRestorePosition(state);
  }

  let outTimer:NodeJS.Timeout|null = null;
  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    document.addEventListener("mouseover", handleDocumentOver);
    document.addEventListener("mouseleave", handleDocumentOut);

    return () => {
      document.removeEventListener("mousedown", handleClick);
      document.removeEventListener("mouseover", handleDocumentOver);
      document.removeEventListener("mouseleave", handleDocumentOut);
    };
  }, []);

  const handleOut = () => { /*outTimer = setTimeout(props.onClose, 50)*/};
  const handleOver = () => { /*console.log("over", outTimer);*/ outTimer && clearTimeout(outTimer)};
  const handleDocumentOver = (e:MouseEvent) => { /*console.log("doc over");*/ e.target == document.children[0] && handleOver() };
  const handleDocumentOut = (event:MouseEvent) => {/*console.log("doc out");*/ if (event.clientY <= 0 || event.clientX <= 0 || (event.clientX >= window.innerWidth || event.clientY >= window.innerHeight)) {handleOut(); }};

  const toggleBookamrksOpen = () => {
    window.localStorage.setItem("bookmarksOpen", JSON.stringify(!bookmarksOpen));
    setBookmarksOpen(!bookmarksOpen);
  }


  const handleClick = (e) => {
    if (!node?.current?.contains(e.target) && opened) {
      props.onClose();
    }
  };

  var recentlyRead:RecentlyRead[] = [];
  if (typeof window !== "undefined")
  {
    try {
      recentlyRead = JSON.parse(window.localStorage.getItem("recentlyRead") || "[]");
    }
    catch (e)
    {
      recentlyRead = [];
    }
  }

  const defaultStateValue = getSignState();
  return (
    <StyledSidebar $opened={opened} onMouseOver={handleOver} onMouseOut={handleOut} ref={node} >
      <CloseButton onClick={props.onClose} className="icon-cancel" />
      <ToCTitle id="tocTitle" />
      <ToCContent id="tocContent"></ToCContent>
      <SidebarToggleContainer>
      { recentlyRead.length > 0 && [<BookmarkTitle key="recently-read" onClick={() => toggleBookamrksOpen()}>{t("recently-read") + (bookmarksOpen ? "▼" : "▲")}</BookmarkTitle>, bookmarksOpen && recentlyRead.map((post,index) => <Bookmark href={`/${locale}/${post.collection}/${post.slug}/${post.locale != locale ? post.locale : ""}`} key={index}>{post.title}</Bookmark>)] }
        
        <SidebarHorizontalContainer>
          <Button onClick={() => props.onDecreaseFontSize()} value="a">a-</Button>
          <ToggleButton onClick={(state:boolean)=> changeRestorePosition(state)} defaultState={restorePosition} title={t("restore-position-tooltip")}>{t("restore-position")}</ToggleButton>
          <Button onClick={() => props.onIncreaseFontSize()} value="A">A+</Button>
          {/* <MultiToggleButton states={readingStates} onClick={e => { window.localStorage.setItem("readingMode", readingStates[e].key); setReadingMode(e)}} activeState={readingMode} /> */}
        </SidebarHorizontalContainer>
        <SidebarHorizontalContainer>
          <ToggleButton onClick={(state:boolean) => props.onOpenDictionary(state)} defaultState={props.isDictionaryOpen} state={props.isDictionaryOpen}>{t("dictionary")}</ToggleButton>
          <MultiToggleButton states={themeButtonStates} onClick={i => props.onChangeTheme(i)} activeState={props.theme} />
        </SidebarHorizontalContainer>
        <SidebarHorizontalContainer>
          <MultiToggleButton states={multiButtonStates} onClick={i => changeSign(multiButtonStates[i].key, false)} defaultState={multiButtonStates.findIndex(e => e.key == defaultStateValue)} />
          <ToggleButton onClick={(state:boolean)=>{toggleHide(["trans-iast", "trans-iast-comment", "chumma-trans", "explanation", "quote-iast", "quote-trans-iast"], !state)}} defaultState={!getState("trans-iast")}>{t("translation-short")}</ToggleButton>
          <ToggleButton onClick={(state:boolean)=>{toggleHide(["iast", "iast-comment", "chumma-iast"], !state)}} defaultState={!getState("iast")}>IAST</ToggleButton>
          <ToggleButton onClick={(state:boolean)=>{toggleHide(["iast-red", "par"], !state)}} defaultState={!getState("iast-red")}><RedToggleButton>IAST</RedToggleButton></ToggleButton>
        </SidebarHorizontalContainer>
      </SidebarToggleContainer>
    </StyledSidebar>
  );
};
