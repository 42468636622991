exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dictionary-tsx": () => import("./../../../src/pages/dictionary.tsx" /* webpackChunkName: "component---src-pages-dictionary-tsx" */),
  "component---src-pages-essentials-tsx": () => import("./../../../src/pages/essentials.tsx" /* webpackChunkName: "component---src-pages-essentials-tsx" */),
  "component---src-pages-gallery-tsx": () => import("./../../../src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-scriptures-tsx": () => import("./../../../src/pages/scriptures.tsx" /* webpackChunkName: "component---src-pages-scriptures-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-studies-tsx": () => import("./../../../src/pages/studies.tsx" /* webpackChunkName: "component---src-pages-studies-tsx" */),
  "component---src-pages-tools-tsx": () => import("./../../../src/pages/tools.tsx" /* webpackChunkName: "component---src-pages-tools-tsx" */),
  "component---src-pages-translation-assistant-tsx": () => import("./../../../src/pages/translation-assistant.tsx" /* webpackChunkName: "component---src-pages-translation-assistant-tsx" */),
  "component---src-templates-langdetection-tsx": () => import("./../../../src/templates/langdetection.tsx" /* webpackChunkName: "component---src-templates-langdetection-tsx" */),
  "component---src-templates-scripture-tsx": () => import("./../../../src/templates/scripture.tsx" /* webpackChunkName: "component---src-templates-scripture-tsx" */)
}

