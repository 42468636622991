import styled from "styled-components";
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { Button } from "../button/button";

const StyledFindOnPage = styled.div`
  position: relative;
  left: 0;
  top: 30px;
  width: 100vw;
  
  z-index: 1000;
  display: flex;
  background-color: ${(props) => props.theme.colors.white};
  padding: 5px; 
  gap: 5px;
`;

const Input = styled.input`
  width:100%;
`;

export const FindOnPage = ( props:{onClose:() => void} ) => {
  const { t } = useTranslation();
  const [text, setText] = useState("");
  const [firstFocus, setFirstFocus] = useState(true);
  const node = useRef<HTMLInputElement>();
  

  function find(text:string) {
    
    window.find(text);
  }

  useEffect(() => { 
    if (firstFocus && node?.current)
    {
      node?.current?.focus();
      setFirstFocus(false);
    }
  });


  return (<StyledFindOnPage>
      <Input autoFocus={true} ref={node} type="text" name="find" onKeyDown={e => {if (e.key == "Enter") find(text);}} onChange={e => {setText(e.target.value);}} placeholder={t("find-on-page") + "..."}></Input>
      <Button onClick={e => find(text)} className="icon-search"></Button>
      <Button onClick={props.onClose} className="icon-cancel"></Button>
  </StyledFindOnPage>);
}