import React, {useState} from "react";
import { Button } from "./button";


type MultiToggleButtonProps = {
  states: Array<{key:string, value:string, title?:string}>;
  onClick: (index:number) => void;
  defaultState?: number;
  activeState?: number;
  className?: string;
}

//TODO: fix external state. is it possble to make it controlled and uncontrolled?
export const MultiToggleButton = (props:MultiToggleButtonProps) => {
  const [state, setState] = useState(props.activeState != undefined ? props.activeState : (props.defaultState ? props.defaultState : 0));
  let currentState = props.activeState != undefined ? props.activeState : state;
  
  return (
    <Button value={props.states[currentState].key} title={props.states[currentState].title}
            onClick={() => {!props.activeState && setState(props.states.length == state + 1 ? 0 : state + 1); props.onClick(props.states.length == currentState + 1 ? 0 : currentState + 1);}}
            className={props.className}>
      {props.states[currentState].value}
    </Button>
  );
};