export enum LanguagePairs {
    SanskritEnglish = "Sanskrit-English",
    EnglishSanskrit = "English-Sanskrit"
}

export const DatabaseSources = [
    {id:"mw", name:"Monier-Williams Sanskrit-English Dictionary", lang:LanguagePairs.SanskritEnglish}, 
    {id:"shs", name:"Shabda-Sagara Sanskrit-English Dictionary", lang:LanguagePairs.SanskritEnglish},
    {id:"wil", name:"Wilson Sanskrit-English Dictionary", lang:LanguagePairs.SanskritEnglish},
    {id:"yat", name:"Yates Sanskrit-English Dictionary", lang:LanguagePairs.SanskritEnglish},
    {id:"lan", name:"Lanman's Sanskrit Reader Vocabulary", lang:LanguagePairs.SanskritEnglish},
    {id:"gst", name:"Goldstücker Sanskrit-English Dictionary", lang:LanguagePairs.SanskritEnglish},
    {id:"cae", name:"Cappeller Sanskrit-English Dictionary", lang:LanguagePairs.SanskritEnglish},
    {id:"ben", name:"Benfey Sanskrit-English Dictionary", lang:LanguagePairs.SanskritEnglish},
    {id:"ap90", name:"Apte Practical Sanskrit-English Dictionary", lang:LanguagePairs.SanskritEnglish},
    {id:"mwe", name:"Monier-Williams English-Sanskrit Dictionary", lang:LanguagePairs.EnglishSanskrit},
    {id:"bor", name:"Borooah English-Sanskrit Dictionary", lang:LanguagePairs.EnglishSanskrit},
    {id:"ae", name:"Apte Student's English-Sanskrit Dictionary", lang:LanguagePairs.EnglishSanskrit},
];